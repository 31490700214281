@import 'styles/variables';

.mat-mdc-tooltip {
  .mdc-tooltip__surface {
    font-size: $fs-default !important;
    max-width: 300px;
    // uses white-space because matTooltip is not accept html inside it
    white-space: pre-line;
  }
}
