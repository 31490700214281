@import 'styles/variables';

mat-form-field.mat-mdc-form-field {
  mat-datepicker-toggle.mat-datepicker-toggle {
    color: $color-primary !important;

    button {
      height: 24px;
      width: 24px;
      --mdc-icon-button-state-layer-size: 24px;
      padding: 0;

      svg.mat-datepicker-toggle-default-icon {
        width: 24px;
        height: 24px;
      }
    }
  }

  &.ng-touched.ng-invalid {
    mat-datepicker-toggle.mat-datepicker-toggle {
      color: $color-error !important;
    }
  }

  &.mat-form-field-disabled {
    mat-datepicker-toggle.mat-datepicker-toggle {
      color: $color-text;
    }
  }
}
