@import 'styles/breakpoints';
@import 'styles/variables';

// to remove borders from the table in invoices
.mat-mdc-table,
table {
  &.table-no-border-top-lg {
    .mat-mdc-header-row,
    .mat-mdc-footer-row,
    .mat-mdc-row,
    .mat-mdc-header-cell,
    .mat-mdc-cell {
      border-top: none !important;
    }

    @include device-small {
      .mat-mdc-row {
        border-top: 1px solid rgba($color-black, .12) !important;
      }
    }
  }
}
