@import 'styles/variables';

//for tabs with avatar instead of label
.tabs-avatar {
  height: 100%;

  .mat-mdc-tab-header {
    display: flex;
    align-self: auto;
    max-height: none;
    min-height: auto;
    margin: 0;
    padding: 10px;
    height: auto;
    border-radius: 6px 0 0 0;
    border: none;

    &.mat-mdc-tab-header-pagination-controls-enabled {
      padding: 10px 0;
    }

    .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {
        .mat-mdc-tab-labels {
          .mat-mdc-tab {
            min-width: auto;
            padding: 0;
            height: auto;
            transform: none !important;
            overflow: visible;

            &:hover {
              background: none;
            }

            &.mdc-tab-indicator--active {
              border-radius: 50%;
              border: 3px solid $color-primary;
            }

            &+.mat-mdc-tab {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .mat-mdc-tab-body-wrapper {
    flex-grow: 1;

    .mat-mdc-tab-body {
      .mat-mdc-tab-body-content {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
