@import 'variables';

$form-field-fs: $fs-default;
$form-field-line-height: 18px;
$form-field-font-weight: 500;
$form-field-side-padding: 15px;
$form-field-padding: 9px $form-field-side-padding;
$form-field-font-family: $font-family;
$form-field-border-radius: 3px;
$form-field-border: 1px solid $color-border-secondary;
