@import 'styles/variables';
@import 'styles/breakpoints';

mat-form-field.mat-mdc-form-field {
  &.year-chooser {
    width: 180px !important;
    padding: 5px;
    .mat-mdc-text-field-wrapper {
      color: black;
      .mat-mdc-form-field-flex {
        background: transparent !important;
        border: none !important;
        color: $color-white;
        font-size: $fs-big !important;
        padding: 0 !important;
        margin-top: 0;

        @include device-small {
          color: $color-primary;
          --side-padding: 5px;
          input.mat-mdc-input-element,
          mat-select.mat-mdc-select {
            padding: 10px var(--side-padding) !important;
          }

          .mat-mdc-form-field-icon-prefix {
            width: 20px;
            left: var(--side-padding);
          }
        }

        .mat-mdc-input-element {
          font-size: $fs-big !important;

          &::placeholder {
            @include device-small {
              color: $color-primary !important;
            }
          }
        }

        .mat-mdc-input-element {
          &::placeholder {
            color: $color-white;
            font-size: $fs-big !important;
          }
        }
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}
