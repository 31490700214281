@import 'styles/variables';

.p-message .p-message-wrapper {
  color: $color-error;
  padding: 0;
}

.p-message .p-message-summary {
  font-weight: normal;
}

.p-message.p-message-error {
  background-color: transparent;
}

.p-message .p-icon {
  height: 1rem;
  width: 1rem;
}
