$size-list: (
  10,
  20,
  30,
  40,
  50,
  60,
  70,
  80,
  90,
  100,
  150,
  200,
  250
);

@each $size in $size-list {
  .img-container-#{$size} {
    width: #{$size}px !important;
    height: #{$size}px !important;

    img {
      max-width: 100%;
      max-height: 100%;
    }

  }
}
// @TODO remove after all img-containers renamed with size
.img-container {
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  &.big {
    width: 100px;
    height: 100px;
  }

  // small icon
  &.small {
    width: 40px;
    height: 40px;
  }
}
