@import "styles/breakpoints";
@import "styles/variables";

.my-tax-report {
  table {
    .mat-mdc-cell {
      border-bottom-style: solid;
    }

    tr {
      &.section-caption {
        &:not(:first-child) {
          td {
            background-color: $color-background-light;
            font-style: italic;
          }
        }

        td {
          font-size: $fs-big;
          font-weight: 600;
        }
      }

      &.total {
        border-top: 3px solid $color-secondary;
        td {
          border-bottom: none;
          font-size: $fs-big;
          font-weight: 600;
        }
      }
      // set bottom border for penultimate row cells
      .mat-mdc-cell,
      .mat-mdc-footer-cell {
        &:after {
          display: none;
        }
      }
      &:last-child {
        .mat-mdc-cell,
        .mat-mdc-header-cell {
          border-top: none !important;
        }
      }

      td {
        font-size: $fs-default;
      }
    }
  }
}

@include device-small {
  .my-tax-report {
    .mat-mdc-cell {
      border-bottom-style: none !important;
    }
    //todo: import my-tax-estimate styles
    .mat-mdc-table {
      .total {
        .mat-mdc-cell:last-child {
          border-top: 1px solid $color-black;
        }
      }
    }
  }
}
