.p-sidebar-bottom {
  border-color: transparent;
  border-radius: 20px;
  height: 58px;
  left: 0;
  margin: 20px auto;
  right: 0;
  width: 345px;

  .p-sidebar-header {
    padding: 0;
  }

  .p-sidebar-content {
    padding: 0 15px;
  }

  .p-sidebar-footer {
    padding: 0;
  }
}
