// Margin

.m-0 {
  margin: 0 !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

// Margin top

.mt-0 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-auto {
  margin-top: auto !important;
}

// Margin bottom
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

// Margin left

.ml-0 {
  margin-left: 0 !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}


.ml-auto {
  margin-left: auto !important;
}

// Margin right

.mr-0 {
  margin-right: 0 !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-auto {
  margin-right: auto !important;
}

// Margin vertical

.mv-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mv-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.mv-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mv-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.mv-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mv-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.mv-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

// Margin horizontal

.mh-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mh-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mh-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mh-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.mh-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mh-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.mh-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.mh-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

// Padding

.p-0 {
  padding: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

// Padding top

.pt-0 {
  padding-top: 0 !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

// Padding bottom

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

// Padding left

.pl-0 {
  padding-left: 0 !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

// Padding right

.pr-0 {
  padding-right: 0 !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

// Padding vertical

.pv-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pv-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pv-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pv-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pv-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pv-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

// Padding horizontal

.ph-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ph-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.ph-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ph-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.ph-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.ph-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.ph-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
