@import 'styles/breakpoints';
@import 'styles/variables';

// to make table text thicker
.mat-mdc-table,
table {
  &.table-bold {
    .mat-mdc-header-cell {
      font-weight: 500;
    }

    .mat-mdc-cell {
      font-weight: 600;
    }
  }
}
