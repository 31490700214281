@import "styles/variables";

//TT-4127 @taxtankit
mat-checkbox.mat-mdc-checkbox.checkbox-select-all {
  width: 100%;
  cursor: pointer;
  padding: 15px;
  //todo: return in 17 angular TT-4148
  //[mat-internal-form-field] {
    .mdc-checkbox {
      margin: 0 10px 0 0;
    }
  //}
}
