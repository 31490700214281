@import 'styles/breakpoints';

.toggler-group {
  display: flex;
  justify-content: center;

  @include device-small {
    flex-wrap: wrap;
  }

  app-toggler {
    &+app-toggler {
      .toggler {
        margin-left: 20px;
      }
    }
  }
}
