@import "styles/variables";

//paints radio with icons into colors
// if you need to extend your class just use radio-icon-color($color) {your-style: good}
@mixin radio-icon-color($color) {
  // todo: 17 angular TT-4148: check mdc-form-field if will be switched only to [mat-internal-form-field]
  &.mat-mdc-radio-checked .mdc-form-field .mdc-label {
    color: $color !important;
    i {
      border-color: $color !important;
      &:before {
        color: $color !important;
      }
    }
    @content;
  }
}

mat-radio-group.budget-radio-group {
  background-color: transparent !important;
  border: none !important;
  height: auto !important;
  margin-bottom: 0 !important;

  mat-radio-button {
    // todo: 17 angular TT-4148: check mdc-form-field if will be switched only to [mat-internal-form-field]
    .mdc-form-field {
      .mdc-radio {
        display: none;
      }

      .mdc-label {
        font-size: 12px !important;
        line-height: 16px !important;
        color: $color-text !important;

        i {
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          width: 40px !important;
          height: 40px !important;
          border-radius: 50% !important;
          border: 2px solid $color-text;
          font-size: 20px !important;
          margin: 0 auto !important;
          position: relative;

          &::before {
            color: $color-text !important;
          }
        }
      }
    }

    &.mat-mdc-radio-checked {
      background-color: transparent !important;
    }

    &.exceeded i::after {
      content: '!';
      position: absolute;
      right: -9px;
      top: -9px;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: $color-error;
      color: white;
      line-height: 14px;
      font-size: 12px;
      font-style: normal;
    }

    &.personal {
      @include radio-icon-color($color-pink);
    }

    &.property {
      @include radio-icon-color($color-primary);
    }

    &.other {
      @include radio-icon-color($color-dark-blue);
    }

    &.work {
      @include radio-icon-color($color-success);
    }

    &.sole {
      @include radio-icon-color($color-warning);
    }
  }
}
