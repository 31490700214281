@import 'styles/breakpoints';
@import 'my-tax';

.report {
  .report-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include device-small {
      flex-direction: column;
    }

    app-property-select {
      @include device-small {
        margin-bottom: 20px;
        width: 100%;
      }
    }
  }
}
