@import 'styles/variables';

mat-form-field.mat-mdc-form-field {
  .mat-mdc-form-field-type-mat-select,
  &.mat-mdc-form-field-type-mat-select {
    mat-progress-spinner.mat-mdc-progress-spinner {
      position: absolute;
      top: -10px;
      right: 0;

      circle {
        fill: $color-white;
      }
    }
  }

  mat-progress-spinner.mat-mdc-progress-spinner {
    margin-right: 10px;
  }
}
