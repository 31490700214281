@import "variables";
@import "breakpoints";

html {
  body {
    background-color: $color-background;
    margin: 0;
  }
}

form {
  background-color: $color-background-light;
  padding: 30px 45px;

  @media (max-width: 767px) {
    padding: 10px 5px;
  }

  &.transparent {
    background-color: transparent;
    padding: 0;
  }
}

// @TODO Front: create component or directive to handle formGroup errors
.form-group-error {
  color: $color-error;
  background-color: rgba($color-error, .1);
  border-radius: 4px;
  padding: 15px;
  margin: 10px 0;
}

//@TODO Artem move after get all design mobile pages
.section-full-width {
  background-color: white;
  padding: 40px;

  @include device-small {
    position: relative;
    background: white;
    padding: 15px;
  }

  &:empty {
    padding: 0;
  }
}

app-inner-card-group {
  .inner-card-group {
    app-inner-card {
      position: relative;
      flex: 1 1 100%;

      .inner-card {
        height: 100%;
      }
    }

    &.transparent {
      app-inner-card {
        .inner-card {
          padding-right: 20px;
          margin-right: 10px;
          margin-left: 10px;

          .inner-card-value {
            border: none;
          }
        }

        &:first-child {
          .inner-card {
            margin-left: 0;
          }
        }

        &:last-child {
          .inner-card {
            margin-right: 0;
          }
        }
      }
    }

    &:not(.transparent) {
      app-inner-card {
        &:not(:last-child) {
          &:after {
            content: '';
            width: 2px;
            height: 50%;
            background-color: $color-border;
            position: absolute;
            right: 0;
            top: 25%;
            z-index: 1;
          }
        }
      }
    }
  }

  & + app-inner-card-group {
    .inner-card-group {
      margin-top: 20px;
    }
  }
}

// State colors
.default {
  color: $color-secondary;
}

.info {
  color: $color-primary;
}

.positive {
  color: $color-success;
}

.negative {
  color: $color-error;
}

// Terms & Privacy styles
.terms-wrapper {
  color: $color-secondary;
  font-size: $fs-big;
  padding: 70px 0;
  margin-bottom: 20px;
  word-break: break-word;

  span {
    display: block;
    margin-bottom: 15px;

    &.description {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 40px;
    }
  }

  h1 {
    margin-bottom: 20px;
  }

  h2 {
    margin-bottom: 30px;
  }

  .section {
    margin-bottom: 30px;
  }

  .term {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 25px;
  }

  .subterm {
    font-weight: bold;
    margin-bottom: 15px;
  }

  .notation {
    font-size: 14px;
    text-align: right;
  }

  // add left padding
  .with-offset {
    padding-left: 30px;
  }
}

// anonym layout content
.anonym-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  mat-toolbar,
  header,
  footer {
    position: static !important;
  }

  section {
    background-color: $color-white;
    width: 100%;
    max-width: 750px;
    min-height: 150px;
    margin: 10px auto 0;
    overflow-x: auto;

    .wrapper {
      .title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        i {
          font-size: 30px;
          margin-right: 20px;

          &:before {
            background: $color-gradient-gold;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        span {
          font-size: 14px;
          line-height: 18px;
        }
      }

      form {
        padding: 30px 45px 0;
        overflow-y: auto;
        overflow-x: hidden;

        .actions {
          padding: 25px 0;

          button {
            &.link {
              background-color: $color-background-light;
            }
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    section {
      .wrapper {
        form {
          padding: 30px 10px 0;
        }

        button {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
  }
}

// @Todo remove after new design will be provided
// Styles for onboarding pages
main.onboard {
  position: relative;
  top: 70px;

  .card {
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, .3);
    border-radius: 20px;
    background: $color-background-light;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    max-width: 700px;

    .card-header {
      text-align: center;
      background-color: #616380;
      mask-image: url('/assets/img/background/card-header-pink.svg');
      mask-repeat: no-repeat;
      mask-position: bottom;
      mask-position-x: 65%;
      padding: 25px 20px 60px;
      color: $color-white;
      font-size: 24px;
      font-weight: 700;
      border-radius: 20px 20px 0 0;

      span {
        display: block;
        font-weight: 500;
        font-size: 14px;
        opacity: .7;
      }
    }

    .card-body {
      padding: 40px 90px;
      text-align: center;
      flex: 1 1 auto;

      .card-text {
        &.lead {
          font-size: 16px;
          font-weight: 500;
          color: $color-text;
          margin-bottom: 30px;
        }
      }

      &.card-form {
        padding: 20px 40px 25px;

        form {
          padding: 0;

          .form-group {
            margin-bottom: 15px;
            position: relative;

            label {
              display: inline-block;
              margin-bottom: 5px;
              color: $color-text;
              font-size: 12px;
              text-transform: uppercase;
              font-weight: 600;
            }

            .form-control {
              display: block;
              width: 100%;
              padding: 3px 5px;
              font-family: $font-family;
              background-color: $color-white;
              background-clip: padding-box;
              min-height: 35px;
              transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
              font-size: 15px;
              border: 1px solid $color-text;
              border-radius: 7px;
              outline: none;
            }
          }

          .login-reg-txt {
            cursor: pointer;
            text-decoration: underline;
            font-size: 12px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}

section {
  background-color: $color-white;
  padding: 30px;
  min-height: 80px;
  box-shadow: $shadow;
  border-radius: 4px;
  position: relative;
  margin-bottom: 20px;

  h5 {
    margin-bottom: 20px;
  }

  // My account overview section styles
  &.account-overview {
    .inner-card {
      padding: 18px;
    }

    @media (max-width: 1199px) {
      .inner-card {
        margin-bottom: 10px;
      }
    }

    @media (max-width: 991px) {
      flex-direction: column;
    }

    @media (max-width: 575px) {
      .inner-card {
        padding: 15px;

        &.user-name-container {
          h2 {
            font-size: 20px;
          }
        }

        h5 {
          font-size: 14px;
        }
      }
    }
  }

  @include device-medium {
    padding: 16px;
  }
}

// Expand icon styles
i.expand-icon {
  display: inline-block;
  cursor: pointer;
  transition: all .1s ease-in;

  &:not(.expanded) {
    transform: rotate(-90deg);
  }

  &:before {
    color: $color-primary;
  }
}

// Information text in case of no data (like for table, expansion panels, e.t.c)
.no-data {
  width: 100%;
  margin: 20px 0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: $color-secondary;

  &.page-center {
    position: absolute;
    top: 300px;

    @include device-small {
      top: 40%;
    }
  }
}

// File icons style
// @Todo refactor after icomoon icons will ve provided
i.file-type {
  font-size: 18px;

  &.icon-doc:before {
    color: $color-primary;
  }

  &.icon-image-jpg:before {
    color: $color-black;
  }

  &.icon-doc-pdf:before {
    color: $color-error;
  }
}
// @TODO move to carousel and refactor this styles file
// carousel block (swipe block)
.carousel {
  display: flex;
  width: 100%;
  overflow: scroll;
  overflow-x: scroll !important;
  -ms-overflow-style: none; /* IE и Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  .carousel-wrapper {
    margin-right: 5%;
    min-width: 90%;
  }
}

.box {
  padding: 15px;
  background-color: $color-background-light;
  box-shadow: $shadow-gray;
  border-radius: 10px;
  border-color: $color-border;
  border-width: 1px;

  @include device-small {
    background-color: transparent;
    box-shadow: none;
  }

  &.dashed {
    border-style: dashed;
  }

  &.solid {
    border-style: solid;
  }

  &.primary {
    border-color: $color-primary;
    background-color: $color-primary-light;
  }
}

@media (max-width: 767px) {
  app-inner-card-group {
    .inner-card-group {
      flex-direction: column;

      &.transparent {
        app-inner-card {
          .inner-card {
            margin-right: 0;
            margin-bottom: 10px;
          }

          &:last-child {
            .inner-card {
              margin-bottom: 0;
            }
          }
        }
      }

      &:not(.transparent) {
        app-inner-card {
          &:not(:last-child) {
            margin-bottom: 0;

            &:after {
              content: '';
              height: 2px;
              width: 50%;
              background-color: $color-border;
              position: absolute;
              right: 25%;
              top: 100%;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .no-data {
    font-size: $fs-default;

    &.page-center {
      top: 30%;
    }
  }
}
