@import "form";
@import "dialog";
@import "checkbox";
@import "chips";
@import "radio";
@import "sidenav";
@import "tabs";
@import "progress-spinner";
@import "expansion-panel";
@import "table";
@import "menu";
@import "slider";
@import "slide-toggle";
@import "tooltip";
@import "stepper";
@import "button-toggle";
@import "bottom-sheet";
@import "tree";
@import "hint";
@import "list";
@import "card";
@import "header";
@import "paginator";
@import "reports";

// @TODO Artem: temp fixes after angular update. Remove during refactoring
button.mat-mdc-icon-button,
button.mat-calendar-body-cell {
  min-width: auto !important;
}

.mat-content.mat-content-hide-toggle {
  margin-left: 0 !important;
}

