@import 'styles/variables';
@import 'styles/breakpoints';

.mat-mdc-menu-panel {
  max-width: none !important;
  margin: 10px;
  min-height: auto !important;

  .mat-mdc-menu-content {
    min-height: auto !important;
    padding: 0 !important;

    .mdc-radio__outer-circle {
      display: none;
    }

    .mat-mdc-menu-item {
      text-decoration: none;
      margin: 0;
      padding: 16px !important;
      height: auto;
      color: $color-secondary;
      line-height: 1.5rem;
      font-weight: 500;
      min-width: auto;

      &[disabled] {
        background: $color-border;
        color: $color-text;
        pointer-events: none;
      }

      @include device-small {
        padding: 7px 8px;
      }

      & > i {
        margin-right: 5px;
      }

      &.delete {
        color: $color-error;
      }

      // "separate" delete button from all with a border
      &:last-child {
        &.delete {
          border-top: 1px solid rgba($color-text, .5);
        }
      }

      &:focus {
        outline: none !important;
      }

      &:before {
        color: $color-secondary;
      }
      // mdc-radio is the radio button itself. we need to show only labels in menu
      mat-radio-button {
        .mdc-radio {
          display: none;
        }
      }
    }
  }
}
