@import 'styles/variables';

//https://github.com/angular/components/blob/main/src/material/checkbox/checkbox.scss
//or node_modules/@angular/material/core/tokens/m2/mdc/_checkbox.scss
@mixin checkbox-theme($outer-color, $inner-color, $inner-check-border-color) {
  //hover
  --mdc-checkbox-selected-hover-state-layer-color: #{$outer-color};
  --mdc-checkbox-unselected-hover-state-layer-color: #{$outer-color};
  --mdc-checkbox-unselected-hover-icon-color: #{$outer-color};
  --mdc-checkbox-selected-hover-icon-color: #{$outer-color};
  //press
  --mdc-checkbox-selected-pressed-state-layer-color: #{$outer-color};
  --mdc-checkbox-unselected-pressed-state-layer-color: #{$outer-color};
  --mdc-checkbox-unselected-pressed-icon-color: #{$outer-color};
  --mdc-checkbox-selected-pressed-icon-color: #{$outer-color};
  //focus
  --mdc-checkbox-unselected-focus-icon-color: #{$outer-color};
  --mdc-checkbox-selected-focus-icon-color: #{$outer-color};
  //other
  --mdc-checkbox-selected-focus-state-layer-color: #{$outer-color};
  --mdc-checkbox-unselected-focus-state-layer-color: #{$outer-color};
  --mdc-checkbox-disabled-selected-icon-color: #{$outer-color};
  --mdc-checkbox-disabled-unselected-icon-color: #{$outer-color};
  --mdc-checkbox-unselected-icon-color: #{$outer-color};
  --mdc-checkbox-selected-icon-color: #{$outer-color};
  --mdc-theme-secondary: #{$outer-color};
  --mdc-theme-text-primary-on-background: #{$inner-color};
  --mdc-checkbox-selected-checkmark-color: #{$inner-check-border-color};
}
