@import 'styles/variables';

mat-form-field.mat-mdc-form-field:not(.mat-mdc-paginator-page-size-select).mat-form-field-disabled {
  --mdc-filled-text-field-disabled-label-text-color: #{$color-deep-blue};
  .mat-mdc-text-field-wrapper {
    background: transparent;
    .mat-mdc-form-field-flex {
      background: $color-disable;

      .mat-mdc-form-field-icon-prefix,
      .mat-mdc-form-field-icon-suffix {

        i {
          &:before {
            color: $color-text;
          }
        }
      }
      //.mat-form-field-infix
      .mat-mdc-form-field-infix {
        .mat-mdc-select-placeholder {
          color: $color-text;
        }
        *[matsuffix] {
          color: $color-text;

          i {
            &:before {
              color: $color-text;
            }
          }
        }
      }
    }
  }
}
