@use 'styles/mixins';
@import 'form-field';
@import 'datepicker';
@import 'select';
@import 'styles/variables';
@import 'styles/form-variables';

// common styles for input and select since it's the same in our design
input.mat-mdc-input-element,
textarea.mat-mdc-input-element,
mat-select.mat-mdc-select {
  --mat-select-enabled-trigger-text-color:  #{$color-deep-blue};
  --mdc-filled-text-field-input-text-color: #{$color-deep-blue};
  --mat-select-disabled-trigger-text-color: #{$color-gray-secondary};
  --mdc-filled-text-field-input-text-placeholder-color: #{$color-gray-secondary};
  --mdc-filled-text-field-disabled-input-text-color: #{$color-gray-secondary};
  --mat-form-field-container-text-size: 14px;
  --app-mat-select-arrow-color: #{$color-gray-secondary};

  @include mixins.font-theme();
  padding: $form-field-padding;

  &.mat-mdc-select-empty {
    .mat-mdc-select-trigger {
      .mat-mdc-select-arrow-wrapper {
        color: var(--app-mat-select-arrow-color);
      }
    }
  }

  .mat-mdc-select-trigger {
    width: 100%;
    align-items: center;
    .mat-mdc-select-arrow-wrapper {
      height: 100%;
      transform: none;
      margin-left: 5px;
      .mat-mdc-select-arrow {
        color: inherit;
      }
    }

    .mat-mdc-select-value {
      color: inherit;
    }
  }
}

mat-form-field.mat-mdc-form-field.compact {
  input.mat-mdc-input-element,
  textarea.mat-mdc-input-element,
  mat-select.mat-mdc-select {
    //padding: 11px 14px;
  }
}

mat-form-field.mat-mdc-form-field {
  textarea {
    min-height: 36px !important;
  }
}
