@import 'styles/variables';

mat-form-field.mat-mdc-form-field {
  &.mat-form-field-invalid:not(.mat-mdc-paginator-page-size-select,.mat-focused) {
    .mat-mdc-text-field-wrapper.mdc-text-field--invalid {

      .mat-mdc-floating-label {
        color: $color-error !important;
      }

      .mat-mdc-form-field-flex {
        border: 1px solid $color-error;
        border-radius: 3px;

        .mat-mdc-form-field-icon-prefix,
        .mat-mdc-form-field-icon-suffix {
          color: $color-error;

          i {
            &:before {
              color: $color-error;
            }
          }

          svg {
            color: $color-error;
          }
        }
        //.mat-form-field-infix
        .mat-mdc-form-field-infix {
          *[matsuffix] {
            color: $color-error;

            i {
              &:before {
                color: $color-error;
              }
            }
          }

          [mattooltip] {
            &:before {
              color: $color-error;
            }
          }
        }
      }
    }
  }
}
