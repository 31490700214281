@import "styles/variables";
@import "styles/breakpoints";

p-listbox {
  .p-listbox {
    border: none;

    .p-listbox-header {
      padding: 0 0 10px;
      border-bottom: 1px solid $color-border;

      @include device-small {
        padding-bottom: 0;
      }

      .p-listbox-filter-container {
        input.p-listbox-filter {
          padding: 10px 10px 10px 35px;
          font-family: 'Montserrat';
          box-shadow: none;
          font-size: $fs-default;

          height: 35px;
          background-color: #f7f7f7;
          border: none;


          &::placeholder {
            color: $color-text;
            font-weight: 500;
          }
        }

        .p-listbox-filter-icon {
          @extend .icon-search;
          left: 10px;
          font-size: 15px;
          top: 50%;
          transform: translateY(-50%);
          right: auto;
          margin: 0;

          &:before {
            color: #c6c6c6;
          }
        }
      }
    }

    .p-listbox-list-wrapper {
      ul.p-listbox-list {
        li {
          &.p-listbox-item {
            padding: 0;
            box-shadow: none;

            &.p-highlight {
              background-color: $color-primary !important;

              &:hover {
                background-color: $color-primary !important;
              }
            }

            &:hover {
              background-color: $color-background-light !important;
            }
          }

          &.p-listbox-empty-message {
            @extend .no-data;
          }
        }
      }
    }
  }
}
