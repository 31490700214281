@import "styles/variables";

.mat-mdc-chip-action-label {
  display: flex;
  align-items: center;
}

mat-chip-option.invalid {
  background-color: $color-error;
  color: white;

  button:before {
    color: white;
  }
}
