// Styles for lists (ul, ol, li)
ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.list-style-disc {
  list-style-type: disc;
  margin: 10px 0;
  padding-left: 20px;

  li {
    list-style-type: disc;
    margin-top: 10px;
  }
}
