$color-primary: #00b7f0;
$color-secondary: #133f5d;
$color-border: #d8d8d8;
$color-gradient-blue: linear-gradient(50.53deg, #13B5EA 0%, #13C5D2 100%);
$color-gradient-blue-dark: linear-gradient(50.53deg, #13B5EA 0%, #13C5D2 25%);
$color-gradient-gold: linear-gradient(90deg, #FABF2E 0%, #FF6159 100%);
$color-gradient-gold-dark: linear-gradient(90deg, #FABF2E 0%, #FF6159 25%);
$color-gradient-green: linear-gradient(90deg, #29C940 0%, #13C4D4 100%);
$color-gradient-green-dark: linear-gradient(90deg, #29C940 0%, #13C4D4 25%);
$color-background: #f0f0f0;
$color-background-light: #fafafa;
$color-background-dark: #f3f3f3;
$color-background-gradient: linear-gradient(50.53deg, #13B5EA 0%, #13C5D2 100%);

$color-inactive: #d8d8d8;
$color-text: #9b9b9b;
// Material label color
$color-text-label: rgba(0, 0, 0, 0.54);
$color-text-dark: #4a4a4a;
$color-text-light: #fafafa;
$color-white: #ffffff;
$color-black: #000000;
$color-violet: #bb3ded;

$font-family:  Montserrat, sans-serif;

$shadow: 0 0 15px 0 rgba(black, .1);
$shadow-gray: 0px 1px 10px rgba(0, 0, 0, 0.17);

$fs-extra-small: 10px;
$fs-small: 12px;
$fs-default: 14px;
$fs-big: 16px;
$fs-extra-big: 18px;

$color-primary-hover: lighten($color-primary, 3%);

//new design colors
//@TODO Artem renave variables after the designer renames the colors in the style guide

$color-primary: #00B7F0;
$color-primary-light: #EBFAFF;
$color-deep-blue: #09435F;
$color-white: #FFFFFF;
$color-gray: #797979;
$color-gray-secondary: #979797;
$color-info: #00a3ff;
$color-success: #29C940;
$color-warning: #FABF2E;
$color-error: #F6465D;
// @TODO Artem: remove and replace with primary-light
$color-filled: #EBFAFF;
$color-disable: #F3F3F3;

// backgrounds
$background-success: rgba(41, 201, 64, 0.1);
$background-warning: rgba(250, 191, 46, 0.1);
$background-secondary: #FAFAFA;
$background-info-section: #F8FDFF;
$background-success-section: #F2FCFF;

// inputs
$background-input-secondary: rgba(0, 0, 0, 0.03);
$color-input-border-3: rgba(216, 216, 216, 0.2);

// border
$color-border-gray: #D8D8D8;
$color-border-secondary: #D8E2EF;
$color-border-selected: rgba(216, 216, 216, 0.2);

// tanks
$color-pink: #CA8EC7;
$color-dark-blue: #1F8AC6;
