@import "styles/variables";

mat-button-toggle-group {
  &.mat-button-toggle-group {
    border: none;

    &.all-items-divide {
      margin-right: 10px;
      mat-button-toggle.mat-button-toggle {
        border-right: solid 1px #e0e0e0 !important;
        border-left: none;
        font-weight: 500;
        font-size: $fs-default;
      }
    }

    mat-button-toggle.mat-button-toggle {
      background: transparent;
      color: $color-primary;
      padding: 0 10px;

      &:hover {
        background-color: transparent;
        color: darken($color-primary, 10%);
      }

      .mat-button-toggle-focus-overlay {
        display: none;
      }

      button.mat-button-toggle-button {
        height: auto;
        min-width: auto;

        &[disabled] {
          background: transparent;
        }

        .mat-button-toggle-label-content {
          line-height: inherit;
          padding: 0;
        }
      }

      &.mat-button-toggle-checked {
        &:hover {
          color: $color-primary;
        }

        button.mat-button-toggle-button {
          .mat-button-toggle-label-content {
            border-bottom: 1px solid $color-primary;
          }
        }
      }
    }
  }
}
