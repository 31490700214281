@import "styles/variables";

mat-progress-spinner.mat-mdc-progress-spinner {
  &.primary {
    circle {
      color: $color-primary;
    }
  }

  &.white {
    --mdc-circular-progress-active-indicator-color: #{$color-white};
  }
}
