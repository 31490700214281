@import 'styles/breakpoints';
@import 'styles/variables';

// todo: wait for redesign TT-4311 and remove
@include device-small {
  .mat-mdc-table,
  table {
    &.table-cell-sm {
      .mat-mdc-header-cell {
        display: table-cell;
      }

      .mat-mdc-cell {
        display: table-cell;
        padding: 5px !important;
      }
    }
  }
}
