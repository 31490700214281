@import 'styles/variables';

mat-form-field.header {
  min-width: 100px !important;

  &.mat-mdc-form-field:not(.mat-mdc-paginator-page-size-select) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
    background-color: transparent;
    border: none;

    mat-select.mat-mdc-select {
      --mat-select-enabled-trigger-text-color: #{$color-white};
      --mat-select-placeholder-text-color: #{$color-white};
      --app-mat-select-arrow-color: #{$color-white};
    }
  }
}
