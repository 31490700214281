@import "styles/variables";

p-carousel {
  .p-carousel {
    .p-carousel-content {
      .p-carousel-container {
        button.p-carousel-prev,
        button.p-carousel-next {
          min-width: auto;
          display: flex;
          width: 25px;
          height: 100%;
          margin: 0;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .pi:before {
            font-size: 25px;
            color: $color-primary;
            font-family: 'primeicons' !important;
          }
        }

        .p-carousel-items-content {

          .p-carousel-items-container {
            .p-carousel-item {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &.hover-controls {
    .p-carousel {
      .p-carousel-content {
        .p-carousel-container {
          button.p-carousel-prev,
          button.p-carousel-next {
            display: none;
          }

          .p-carousel-items-content {
            margin: 0 25px;
          }
        }
      }
    }

    &:hover {
      .p-carousel {
        .p-carousel-content {
          .p-carousel-container {
            button.p-carousel-prev,
            button.p-carousel-next {
              display: flex;

              &:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: none;
              }
            }

            .p-carousel-items-content {
              margin: 0;
            }
          }
        }
      }
    }
  }

  &.custom-carousel {
    .p-carousel-content {
      width: calc(100% + 70px);
      position: relative;
      left: -35px;
    }
  }

  &:not(.hover-controls) {
    .p-carousel-items-content {
      margin: 0 25px !important;
    }
  }
}
