@import 'styles/variables';
@import 'styles/fonts';
@import 'styles/form-tabs';
@import 'styles/material';
@import 'styles/layout';
@import 'styles/icomoon';
@import 'styles/intercom';
@import 'styles/header';
@import 'styles/headlines';
@import 'styles/buttons';
@import 'styles/links';
@import 'styles/lists';
@import 'styles/map';
@import 'styles/breakpoints';
@import 'styles/common';
@import "styles/helpers";
@import "styles/images";
@import "styles/components";
@import "styles/application-preloader";
@import "styles/prime";
@import "styles/form-controls";
@import "styles/ng-pdf-viewer";
@import "styles/themes";

* {
  font-family: $font-family;
  box-sizing: border-box;

  // Thin scrollbar
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $color-text-light;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-primary;

    &:hover {
      background: darken($color-primary, 5%);
    }
  }

  iframe[name='us-entrypoint-buttonV2'] {
    z-index: 1 !important;
  }
}


@media (max-width: 575px) {
  iframe[name='us-entrypoint-buttonV2'] {
    display: none !important;
  }
}

html {
  font-size: 14px;
}

html, body {
  height: 100%;
}
