@import 'styles/variables';

mat-form-field.mat-mdc-form-field {
  &.mat-focused {
    &:not(.mat-mdc-paginator-page-size-select):not(.header) {
      width: 100%;

      .mat-mdc-text-field-wrapper {
        &.mdc-text-field--focused {
          .mat-mdc-form-field-flex {
            border: 1px solid $color-primary;
            border-radius: 2px;
          }
        }
      }
    }
  }
}
