@import 'styles/variables';
@import 'styles/breakpoints';

.year-chooser {
  input.mat-mdc-input-element,
  mat-select.mat-mdc-select {
    --mat-select-enabled-trigger-text-color:  #{$color-white};
    --mdc-filled-text-field-input-text-color: #{$color-white};

    .mat-mdc-select-placeholder, .mat-mdc-select-trigger {
      color: $color-white;
      font-size: $fs-big;
      @include device-small {
        color: $color-primary;
      }
    }
    .mat-mdc-select-arrow {
      opacity: 0;
    }

    @include device-small {
      --mdc-filled-text-field-input-text-color: #{$color-primary};
    }
  }
}
