@import 'styles/variables';

.pointer {
  cursor: pointer;
}

.help {
  cursor: help;
}

// Remove shadow from element

.shadow-none {
  box-shadow: none !important;
}

.inactive {
  background-color: rgba($color-inactive, .5);
  cursor: default;
}

// translucent overlay
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba($color-white, .5);
}
