@import 'styles/variables';
@import 'styles/breakpoints';

mat-sidenav-container.mat-sidenav-container {
  --mat-sidenav-container-divider-color: transparent;
  --mat-sidenav-container-background-color: transparent;
  --mat-sidenav-container-elevation-shadow: none;
  background-color: transparent;
  /* needs for empty tanks */
  min-height: 85vh;

  @include device-small {
    z-index: auto;
    --mat-sidenav-container-background-color: #{$color-background-light};
  }

  &.hidden {
    mat-sidenav.mat-sidenav {
      visibility: hidden !important;
    }

    mat-sidenav-content {
      margin: 0 !important;
    }
  }

  mat-sidenav.mat-sidenav {
    display: block !important;
    visibility: visible !important;
    transform: none !important;
    overflow-y: visible !important;
    top: 70px !important;

    @include device-small {
      transition: .4s ease;
      top: 0 !important;
    }

    app-year-chooser,
    app-user-switcher, app-impersonator {
      transition: .4s;
    }

    &.collapsed {
      transition: .4s ease;

      app-user-switcher, app-impersonator, app-year-chooser {
        opacity: 0;
        visibility: hidden;
      }

      &.mobile {
        opacity: 0 !important;

        @include device-small {
          left: -220px;
        }
      }
    }

    .mat-drawer-inner-container {
      display: flex;
      flex-direction: column;
      padding: 25px 0 0 5px;

      .logo-container {
        margin-bottom: 22px;
        height: 36px;

        img.logo {
          height: 36px;
          cursor: pointer;
          transition: .4s;
          transform-origin: left;
        }
      }
    }
  }

  mat-sidenav-content.mat-sidenav-content {
    padding: 30px 40px 0;
    background-color: transparent;
    display: flex;
    flex-direction: column;

    &.mobile {
      overflow-x: hidden;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0 !important;
    }
  }
}

// transition-duration is necessary to work correctly in Safari browser
.mat-drawer-transition .mat-drawer-content {
  transition-duration: inherit !important;
}
