@import 'styles/variables';
@import 'styles/breakpoints';

@mixin flex() {
  display: flex !important;
}

// flex direction: column

.f-column {
  @include flex;
  flex-direction: column;
}

.f-column-reverse {
  @include flex;
  flex-direction: column-reverse;
}


// flex direction: row

.f-row {
  @include flex;
  flex-direction: row;
}

// flex vertical alignment

.align-v-top {
  @include flex;
  align-items: flex-start;
}

.align-v-center {
  @include flex;
  align-items: center;
}

.align-v-baseline {
  @include flex;
  align-items: baseline;
}

.align-v-bottom {
  @include flex;
  align-items: flex-end;
}

// flex horizontal alignment

.align-h-left {
  @include flex;
  justify-content: flex-start !important;
}

.align-h-center {
  @include flex;
  justify-content: center !important;
}

.align-h-right {
  @include flex;
  justify-content: flex-end;
}

.align-h-space-between {
  @include flex;
  justify-content: space-between !important;
}

.align-h-space-around {
  @include flex;
  justify-content: space-around;
}

// text alignment
.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.wb-all {
  word-break: break-all;
}

// text alignment for device-medium
@include device-medium {
  .text-md-center {
    text-align: center !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-left {
    text-align: left !important;
  }
}
