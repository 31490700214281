@use "@angular/material" as mat;
@import 'styles/variables';

mat-slide-toggle.mat-mdc-slide-toggle {
  --slider-toggle-width: 40px;
  --slider-toggle-height: 20px;
  --slider-knob-diameter: 16px;

  width: var(--slider-toggle-width);

  button.mdc-switch {
    --mdc-switch-track-shape: 10px;
    --mdc-switch-track-height: var(--slider-toggle-height);
    --mdc-switch-handle-height: var(--slider-knob-diameter);
    --mdc-switch-handle-width: var(--slider-knob-diameter);
    //knob background selected
    --mdc-switch-selected-focus-state-layer-color: #{$color-primary};
    --mdc-switch-selected-hover-state-layer-color: #{$color-primary};
    --mdc-switch-selected-pressed-state-layer-color: #{$color-primary};
    --mdc-switch-selected-focus-track-color: #{$color-primary};
    --mdc-switch-selected-hover-track-color:#{$color-primary};
    --mdc-switch-selected-pressed-track-color: #{$color-primary};
    --mdc-switch-selected-track-color: #{$color-primary};
    --mdc-switch-disabled-selected-track-color: #{$color-primary};
    //knob background unselected
    --mdc-switch-unselected-track-color: #{$color-border-secondary};
    --mdc-switch-unselected-focus-state-layer-color: #{$color-border-secondary};
    --mdc-switch-unselected-focus-track-color: #{$color-border-secondary};
    --mdc-switch-unselected-hover-state-layer-color: #{$color-border-secondary};
    --mdc-switch-unselected-hover-track-color: #{$color-border-secondary};
    --mdc-switch-unselected-icon-color: #{$color-border-secondary};
    --mdc-switch-unselected-pressed-state-layer-color: #{$color-border-secondary};
    --mdc-switch-unselected-pressed-track-color: #{$color-border-secondary};
    --mdc-switch-disabled-unselected-track-color: #{$color-border-secondary};
    // knob color styles
    --mdc-switch-selected-focus-handle-color: #{$color-white};
    --mdc-switch-selected-hover-handle-color: #{$color-white};
    --mdc-switch-selected-pressed-handle-color: #{$color-white};
    --mdc-switch-selected-handle-color: #{$color-white};
    --mdc-switch-unselected-focus-handle-color: #{$color-white};
    --mdc-switch-unselected-handle-color: #{$color-white};
    --mdc-switch-unselected-hover-handle-color: #{$color-white};
    --mdc-switch-unselected-pressed-handle-color: #{$color-white};
    --mdc-elevation-overlay-color:#{$color-white};

    width: var(--slider-toggle-width) !important;
    min-width: var(--slider-toggle-width) !important;
    height: var(--slider-toggle-height) !important;
    border-radius: var(--mdc-switch-track-shape) !important;
  }

  .mdc-switch__track {
    width: var(--slider-toggle-width);
  }

  .mdc-switch .mdc-switch__handle-track {
    --knob-shift: 2px;
    left: var(--knob-shift);
    width: calc(var(--slider-toggle-width) - var(--mdc-switch-handle-width) - var(--knob-shift)*2);
  }

  .mdc-switch__icons {
    display: none;
  }
}
