@use 'styles/mixins';
@import "styles/variables";
@import "styles/form-variables";

.p-inputwrapper {
  .p-component {
    @include mixins.font-theme();
    background: white;
    width: 100%;

    .p-inputtext {
      border: none;
      border-radius: 0;
      font-size: $fs-default;
      font-family: $form-field-font-family;
      color: $color-deep-blue;
      padding: $form-field-padding;
      width: 100%;
      &:disabled, &[readonly] {
        background: $color-disable;
        color: $color-gray-secondary !important;
        opacity: 1;
      }

      @include device-small {
        font-size: $fs-big;
      }

      &::placeholder {
        color: $color-text;
        font-weight: 500;
      }

      &:enabled {
        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .p-input-icon-right {
    & > i {
      margin-top: -0.5rem;
      position: absolute;
      top: 50%;
      right: 1rem;
    }
  }
}

//custom quantity input
.quantity-selector {
  .p-inputwrapper {
    .p-component {
      padding: 0;
      height: 30px;
      .p-inputtext {
        text-align: center;
        width: 40px;
        border-width: 1px 0;
        border-style: solid;
        border-color: $color-text;
      }

      .p-button.p-button-icon-only {
        padding: 5px !important;
        width: 30px !important;
        min-width: 30px;
        border: 1px solid $color-text;

        &:hover {
          background: transparent !important;
        }
      }
    }
  }
}
