@use 'styles/material/checkbox/mixins' as mixins;
@import 'styles/variables';
@import 'styles/form-controls/checkbox';

.my-tax-report {
  @include mixins.checkbox-theme($color-primary, $color-white, $color-white);
  mat-checkbox {
    &.mat-mdc-checkbox {
      --mdc-checkbox-state-layer-size: 0px;
      //todo: return in 17 angular TT-4148
      //[mat-internal-form-field] {
        .mdc-checkbox {
          height: 30px;
          width: 30px;

          .mdc-checkbox__background {
            @include mixins.checkbox-theme($color-primary, $color-primary, $color-white);
            border: 1px solid $color-primary;
            background-color: $color-white;
            height: 30px;
            width: 30px;
            svg.mdc-checkbox__checkmark {
              opacity: 0;
            }
          }
        }

        .mdc-checkbox__background {
          border-radius: 50%;
        }
      //}

      &.mat-mdc-checkbox-checked {
        //todo: return in 17 angular TT-4148
        //[mat-internal-form-field] {
          .mdc-checkbox {
            .mdc-checkbox__background {
              @include mixins.checkbox-theme($color-white, $color-white, $color-white);
              border: 1px solid $color-primary;
              border-color: $color-primary !important;
              svg.mdc-checkbox__checkmark {
                background-color: $color-white;
                border: 1px solid $color-primary;
              }
            }
          }
        //}

        .mdc-checkbox__background {
          &:before {
            content: "\e90e";
            color: $color-primary;
            font-size: 20px;
            font-family: 'icomoon';
            position: absolute;
            z-index: 1;
          }
        }
      }


      &.mat-mdc-checkbox-disabled,
      &.mat-mdc-checkbox-disabled.mat-mdc-checkbox-checked{
        //todo: return in 17 angular TT-4148
        //[mat-internal-form-field] {
          .mdc-checkbox__background {
            @include mixins.checkbox-theme($color-border-secondary, $background-success-section, $background-success-section);
            border: $checkbox-unchecked-border !important;
            &:before {
              color: $checkbox-outline-color;
            }
          }
        //}
      }
    }

    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~{
      .mdc-checkbox__background {
        border-color: $color-primary;
        background-color: $color-white;
      }
    }
  }

  .checkbox-wrapper {
    background: $color-white;
    border-radius: 3px;
    border: 1px solid $color-text;
    padding: 20px 10px;
    width: 100%;
  }
}
