@import 'styles/variables';

//those variables can be found in custom mat themes with select .mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
@mixin tab-label-text-color($active-color, $inactive-color, $inactive-color-hover: $inactive-color, $inactive-color-focus: $inactive-color) {
  --mat-tab-header-active-label-text-color: #{$active-color};
  --mat-tab-header-active-focus-label-text-color: #{$active-color};
  --mat-tab-header-active-hover-label-text-color: #{$active-color};
  --mat-tab-header-inactive-label-text-color: #{$inactive-color};
  --mat-tab-header-inactive-focus-label-text-color: #{$inactive-color-focus};
  --mat-tab-header-inactive-hover-label-text-color: #{$inactive-color-hover};
}

@mixin tab-hover-bg($active-color, $inactive-color: $active-color) {
  mat-mdc-tab-link,
  .mat-mdc-tab {
    // need to be wrapped or it won't work
    --mat-tab-header-active-ripple-color: $active-color;
    --mat-tab-header-inactive-ripple-color: $inactive-color;
  }
}

//in case you'll need to change the underline's color: --mdc-tab-indicator-active-indicator-color
