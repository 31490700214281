@import 'styles/variables';
@import 'styles/breakpoints';

mat-tree.mat-tree {
  mat-nested-tree-node.mat-nested-tree-node {
    div[role=group] {
      padding-left: 40px;

      @include device-x-small {
        padding: 0;
      }
    }

    >*[mattreenodetoggle] {
      cursor: pointer;

      &:hover {
        background-color: $color-background !important;
      }

      &:before {
        content: "";
        font-family: "icomoon" !important;
        border: none;
        font-size: 8px;
        color: #00b7f0;
        margin-right: 10px;
        transform: rotate(-90deg);
      }
    }

    &[aria-expanded="true"] {
      >*[mattreenodetoggle] {
        &:before {
          transform: rotate(0deg);
        }
      }
    }

    &[aria-expanded="false"] {
      >*[role=group] {
        display: none;
      }
    }
  }

  mat-tree-node.mat-tree-node {
  }

  *[role=group] > .mat-tree-node {
    padding-left: 40px;
  }

  *[mattreenodetoggle] {
    padding: 10px;
  }
}
