@import 'styles/variables';

// text colors

.c-primary {
  color: $color-primary !important;
}

.c-secondary {
  color: $color-secondary !important;
}

.c-success {
  color: $color-success !important;
}

.c-black {
  color: $color-black;
}

.c-white {
  color: $color-white !important;
}

.c-text {
  color: $color-text;
}

.c-text-dark {
  color: $color-text-dark;
}

.c-text-light {
  color: $color-text-light;
}

.c-danger, .c-error {
  color: $color-error !important;
}

.c-warning {
  color: $color-warning !important;
}

.c-gray-secondary {
  color: $color-gray-secondary !important;
}

// background colors

.bg-primary {
  background-color: $color-primary !important;
}

.bg-secondary {
  background-color: $color-secondary !important;
}

.bg-success {
  background-color: $color-success !important;
}

.bg-black {
  background-color: $color-black !important;
}

.bg-white {
  background-color: $color-white !important;
}

.bg-light {
  background-color: $color-background-light !important;
}

// transparent background
.bg-transparent {
  background-color: transparent !important;
}

// icon colors

.ic-primary {
  &:before {
    color: $color-primary !important;
  }
}

.ic-secondary {
  &:before {
    color: $color-secondary !important;
  }
}

.ic-success {
  &:before {
    color: $color-success !important;
  }
}

.ic-warning {
  &:before {
    color: $color-warning !important;
  }
}

.ic-error {
  &:before {
    color: $color-error !important;
  }
}

.ic-black {
  &:before {
    color: $color-black !important;
  }
}

.ic-white {
  &:before {
    color: $color-white !important;
  }
}

.ic-text {
  &:before {
    color: $color-text !important;
  }
}

.ic-gray-secondary {
  &:before {
    color: $color-gray-secondary !important;
  }
}

// new design colors
.c-deep-blue {
  color: $color-deep-blue !important;
}
