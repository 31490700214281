@use 'styles/mixins' as mixins;
@import 'mixins';
@import 'styles/variables';

/*
  Location: The tabs are located at the top of the main workspace.
  Target: These tabs are designed to switch between the main categories or sections within the selected Tank.
  Structure: They are designed in a more compact way to save space and allow the user to quickly switch between Tank items
*/
nav.mat-mdc-tab-nav-bar.mat-mdc-tab-header.primary,
.mat-mdc-tab-group.primary {
  --app-tab-label-fs: #{$fs-small};
  --app-tab-label-fw: 600;
  --app-tab-label-line-heigth: 14px;
  @include tab-hover-bg($color-gradient-blue, $color-primary-light);

  .mat-mdc-tab-header {
    background: transparent !important;
    .mat-mdc-tab-header-pagination {
      position: absolute;
      border-radius: 0;
    }
  }

  .mdc-tab {
    border-radius: 6px;
    height: 26px !important;
    padding: 6px 12px;
    @include mixins.background($color-white, darken($color-primary-light, 5%));
    @include tab-label-text-color($color-white, $color-text);

    &.mdc-tab-indicator--active {
      background: $color-gradient-blue;
    }
  }
}
