/**
Styles related to SwiperJs library (carousel)
@TODO Artem: we used import as 'swiper/swiper.min.css' before,
it is broken after update. Check, probable something changed in tsconfig or angular.json
 */
@import "../node_modules/swiper/swiper.min.css";
@import "../node_modules/swiper/modules/pagination/pagination.scss";
@import "styles/variables";

.swiper-pagination {
  bottom: 0 !important;

  .swiper-pagination-bullet {
    &.swiper-pagination-bullet-active {
      background-color: $color-primary;
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  background-color: $color-white;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  padding: 5px 10px;
  position: absolute;
  top: 50%;
  z-index: 2;

  &:before {
    font-family: 'icomoon';
  }
}

.swiper-button-next {
  right: 15px;

  &:before {
    content: "\e945";
  }
}

.swiper-button-prev {
  &:before {
    content: "\e956";
  }
}

.swiper-button-disabled {
  opacity: .4;
}
