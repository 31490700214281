@import 'styles/variables';

.header-menu-button {
  align-items: center;
  background-color: $color-primary;
  border-radius: 50px;
  display: flex;
  height: 40px;
  min-width: 40px;
  width: 40px;

  &:hover {
    background-color: $color-primary-hover;
  }
}

// @TODO move to buttons
.header-menu-item {
  align-items: center;
  background-color: white;
  display: flex;
  height: auto;
  margin: 0;
  padding: 15px;
  text-align: left;
  width: 100%;

  &:hover {
    background-color: $color-background-light;
  }

  &.icon-chat-circle {
    &:before {
      font-size: 20px;
    }
  }
}
