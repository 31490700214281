@import 'palette';

//$primaryColor: map-get($palette-primary, 500) !default;
$primaryColor: $color-primary !default;
$primaryDarkColor: $color-primary !default;
$primaryDarkerColor: $color-secondary !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #EFF6FF !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, .24) !default;

@import 'node_modules/primeng-sass-theme/themes/aura/aura-light/variables';
@import 'node_modules/primeng-sass-theme/themes/aura/aura-light/blue/fonts';
@import 'node_modules/primeng-sass-theme/theme-base/_components';
@import 'node_modules/primeng-sass-theme/themes/aura/aura-light/extensions/_extensions';
