//visual styles such as shadows, scroll colors, etc.

//class that sets the shadow when scrolling
.scroll-shadows {
  overflow: auto;
  background:
    linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 20px, 100% 20px, 100% 4px, 100% 4px;
  background-attachment: local, local, scroll, scroll;
}

// wave displayed in block
.wave-bottom {
  background-image: url('/assets/img/dialog-wave.svg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}
