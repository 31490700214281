/**
 * Mixins replacing media queries. Used for
 1500px (device-extra-large),
 1199px (device-large),
 991px (device-medium),
 767px (device-small),
 575px (device-x-small)
 */

$device-extra-large: 1500px;
$device-large: 1199px;
$device-large: 1199px;
$device-medium: 991px;
$device-small: 767px;
$device-x-small: 575px;

@mixin device-extra-large {
  @media (max-width: $device-extra-large) {
    @content;
  }
}

@mixin device-large {
  @media (max-width: $device-large) {
    @content;
  }
}

@mixin device-medium {
  @media (max-width: $device-medium) {
    @content;
  }
}

@mixin device-small {
  @media (max-width: $device-small) {
    @content;
  }
}

@mixin device-x-small {
  @media (max-width: $device-x-small) {
    @content;
  }
}

@mixin sm-width($width) {
  width: $width;

  @include device-small {
    width: auto;
  }
}
