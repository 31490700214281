@import "styles/variables";

.hover-background-primary {
  &:hover {
    border-radius: 50px;
    background-color: $color-primary-hover !important;
  }
}

.hover-background-light {
  &:hover {
    background: $color-background-light;
  }
}
