@import "styles/variables";

.notification-indicator {
  background-color: $color-border;
  border-radius: 50%;
  height: 10px;
  position: absolute;
  right: 0;
  top: 0;
  width: 10px;
  z-index: 1;

  &.primary {
    background-color: $color-primary;
  }

  &.secondary {
    background-color: $color-secondary;
  }

  &.warning {
    background-color: $color-warning;
  }

  &.error {
    background-color: $color-error;
  }

  &.success {
    background-color: $color-success;
  }

  &.with-value {
    align-items: center;
    border-radius: 10px;
    color: white;
    display: flex;
    font-size: 10px;
    font-weight: bold;
    justify-content: center;
    height: 16px;
    min-width: 16px;
    padding: 5px;
    right: 0;
    top: 0px;
    transform: translate(50%, -50%);
    width: auto;
  }
}
