@import 'styles/breakpoints';

.mat-bottom-sheet-container-medium {
  min-width: 384px;
}

.mat-bottom-sheet-container-large {
  min-width: 1200px !important;
}

.mat-bottom-sheet-container-xlarge {
  min-width: 1200px !important;
}

.mat-bottom-sheet-container {
  @include device-small {
    border-radius: 20px 20px 0 0;
    padding: 30px 20px!important;
    max-height: 80% !important;
  }
}
