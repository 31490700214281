@import "styles/breakpoints";
@import "styles/variables";

.p-toast-top-right {
  top: 60px !important;
  right: 40px !important;

  @include device-small {
    top: 20px !important;
    right: 20px !important;
  }
}

.p-toast {
  z-index: 9;
  border-radius: 15px;

  @include device-x-small {
    width: calc(100% - 40px) !important;
  }

  .p-toast-message {
    background-color: white !important;
    border-radius: 15px;

    .p-toast-message-content {
      align-items: flex-start;

      .p-toast-message-text {
        color: $color-secondary;
      }

      .p-toast-message-icon {
        font-size: 16px;
      }
    }

    .p-toast-icon-close {
      .p-toast-icon-close-icon {
        &:before {
          color: $color-text;
        }
      }
    }


    &.p-toast-message-info {
      border-left: 4px  solid $color-info;

      .p-toast-message-icon {
        &:before {
          color: $color-info;
        }
      }
    }

    &.p-toast-message-success {
      border-left: 4px solid $color-success;

      .p-toast-message-icon {
        &:before {
          color: $color-success;
        }
      }
    }

    &.p-toast-message-warn {
      border-left: 4px solid $color-warning;

      .p-toast-message-icon {
        &:before {
          color: $color-warning;
        }
      }
    }

    &.p-toast-message-error {
      border-left: 4px solid $color-error;

      .p-toast-message-icon {
        &:before {
          color: $color-error;
        }
      }
    }
  }
}
