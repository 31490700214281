@import 'styles/variables';

* {
  letter-spacing: normal !important;
}

// font sizes
.fs-extra-small {
  font-size: $fs-extra-small !important;
}
.fs-small {
  font-size: $fs-small !important;
  line-height: 16px;
}
.fs-default {
  font-size: $fs-default !important;
  line-height: 18px;
}
.fs-big {
  font-size: $fs-big !important;
  line-height: 20px;
}
.fs-extra-big {
  font-size: $fs-extra-big !important;
}

// font weights
.fw-100 {
  font-weight: 100 !important;
}
.fw-200 {
  font-weight: 200 !important;
}
.fw-300, .fw-lighter {
  font-weight: 300 !important;
}
.fw-400, .fw-light {
  font-weight: 400 !important;
}
.fw-500, .fw-normal {
  font-weight: 500 !important;
}
.fw-600, .fw-bold {
  font-weight: 600 !important;
}
.fw-700, .fw-bolder{
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}

// uppercase
.uppercase {
  text-transform: uppercase;
}

// white-space todo: switch to bootstrap helpers and remove text classes since they're in bootstrap
.text-normal {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

// text display
.text-truncated {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

// break text without whitespace to fit within parent's width
.break-word {
  word-break: break-word;
}

// font-style
.fs-italic {
  font-style: italic !important;
}

label {
  cursor: pointer;
}

.link {
  color: $color-primary;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}

p {
  margin: 0 0 20px 0;
}
