@use "styles/mixins" as mixins;
@import "styles/variables";

.scrollable-wrapper {
  flex-grow: 1;
  position: relative;
  overflow-y: hidden;
  @include mixins.disable-text-selection();

  .scrollable-content {
    display: flex;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none; /* IE и Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.left-shadow,
  &.right-shadow {
    &:before,
    &:after {
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 1;
    }
  }

  &.left-shadow {
    &:before {
      left: 0;
      box-shadow: 0 0 35px 40px $color-background;
      transition: box-shadow .2s ease-in-out;
    }
  }

  &.right-shadow {
    &:after {
      right: 0;
      box-shadow: 0 0 35px 40px $color-background;
      transition: box-shadow .2s ease-in-out;
    }
  }
}
