// display

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-none {
  display: none;
}

// width

.w-100 {
  width: 100% !important;
}

.max-w-100 {
  max-width: 100%;
}

.min-w-auto {
  min-width: auto !important;
}

.w-auto {
  width: auto !important;
}

// height

.h-100 {
  height: 100%;
}

.max-h-100 {
  max-height: 100%;
}

.min-h-0 {
  min-height: 0 !important;
}

.h-auto {
  height: auto;
}

.invisible {
  visibility: hidden;
}

// Overflow styles
.o-auto {
  overflow: auto;
}

.o-hidden {
  overflow: hidden;
}

.o-visible {
  overflow: visible;
}

.o-scroll {
  overflow: scroll;
}

// box-sizing styles
.border-box {
  box-sizing: border-box !important;
}
