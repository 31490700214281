@use "styles/mixins";
@import "styles/variables";
@import "styles/form-variables";

.p-inputnumber-wrapper {
  width: 100%;

  &.compact {
    label {
      display: none;
    }
  }

  &.no-error {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  &:not(.disabled) label.required:after {
    content:" *";
    color: $color-error;
  }

  label {
    color: $color-deep-blue;
    display: block;
    margin-bottom: 3px;
    @include mixins.font-theme();
  }
}

p-inputnumber {
  &.p-inputwrapper-focus {
    border-color: $color-primary !important;
  }
}
