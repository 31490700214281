@use 'styles/mixins' as mixins;
@import 'mixins';
@import 'styles/variables';

/*
  Location: These tabs are located below the main workspace.
  Target: They can represent subcategories or additional sections within the selected top section.
   For example, "Expenses", "Income", "Deprecations", etc.
  Structure: Larger tabs make them easy to notice and use for detailed management of content
   or functionality within the selected section.
*/
nav.mat-mdc-tab-nav-bar.mat-mdc-tab-header.secondary,
.mat-mdc-tab-group.secondary {
  --app-tab-label-fs: #{$fs-small};
  --app-tab-label-fw: 600;
  --app-tab-label-line-heigth: 14px;
  @include tab-hover-bg($color-background-light, $color-primary-light);

  .mat-mdc-tab-header {
    .mat-mdc-tab-header-pagination {
      display: none !important;
    }
  }

  .mdc-tab {
    border-radius: 10px !important;
    height: 34px !important;
    padding: 14px 10px;
    @include mixins.background($color-white, darken($color-background-light, 5%));
    @include tab-label-text-color($color-primary, $color-text);

    &.mdc-tab-indicator--active {
      background: $color-primary-light;
    }
  }
}
