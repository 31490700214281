@import 'styles/variables';

.my-tax-report {
  mat-radio-group.mat-mdc-radio-group {
    &:not(.radio-icons):not(.default) {
      background-color: $color-white;
      border: none;
      height: 70px;
      padding: 0;

      mat-radio-button {
        &:first-child {
          margin-right: 10px;
        }

        &.mat-mdc-radio-button {
          border: 1px solid $color-text;
          padding: 0 10px;
          // todo: 17 angular TT-4148: check mdc-form-field if will be switched only to [mat-internal-form-field]
          .mdc-form-field {
            $radio-diameter: 30px;
            .mdc-radio,
            .mat-radio-ripple.mat-ripple {
              border-radius: 50%;
              display: block;
              height: $radio-diameter;
              margin-right: 10px;
              left: 0;
              position: relative;
              top: 0;
              width: $radio-diameter;

              .mdc-radio__outer-circle {
                border-color: $color-primary;
                border-width: 1px;
                height: $radio-diameter;
                left: 0;
                width: $radio-diameter;
              }
            }

            .mdc-radio__background::before {
              top: 0;
              left: 0;
              width: $radio-diameter;
              height: $radio-diameter;
            }

            .mdc-label {
              color: $color-black !important;
              font-weight: normal;
              text-align: left;
            }
          }
          &.mat-mdc-radio-checked {
            background-color: transparent;
            // todo: 17 angular TT-4148: check mdc-form-field if will be switched only to [mat-internal-form-field]
            .mdc-form-field {
              .mdc-radio {
                .mdc-radio__outer-circle {
                  border-color: $color-primary;
                }

                .mdc-radio__inner-circle {
                  background-color: transparent;
                  border-color: transparent;
                  display: flex !important;
                  justify-content: center;
                  align-items: center;
                  position: relative;
                  height: 30px;
                  transform: scale(1);
                  width: 30px;

                  &:before {
                    font-family: 'icomoon';
                    content: "\e90e";
                    color: $color-primary;
                    position: absolute;
                    font-size: 20px;
                  }
                }

                .mdc-radio__inner-circle {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}
