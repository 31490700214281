@import 'styles/variables';

.my-tax-report {
  .sub-code {
    align-items: center;
    border: 1px solid $color-primary;
    background: $color-white;
    color: $color-primary;
    display: flex;
    font-size: $fs-big;
    font-weight: bold;
    justify-content: center;
    height: 36px;
    margin-right: 10px;
    min-width: 36px;
  }
}
