@import 'styles/variables';

#root-preloader {
  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $color-primary;

    .loading-elements {
      div {
        width: 16px;
        height: 16px;
        -webkit-animation: ball-pulse 1s ease infinite;
        -moz-animation: ball-pulse 1s ease infinite;
        -o-animation: ball-pulse 1s ease infinite;
        animation: ball-pulse 1s ease infinite;

        &:nth-child(1) {
          -webkit-animation-delay: -200ms;
          -moz-animation-delay: -200ms;
          -o-animation-delay: -200ms;
          animation-delay: -200ms;
        }

        &:nth-child(2) {
          -webkit-animation-delay: -100ms;
          -moz-animation-delay: -100ms;
          -o-animation-delay: -100ms;
          animation-delay: -100ms;
        }

        &:nth-child(3) {
          -webkit-animation-delay: 0ms;
          -moz-animation-delay: 0ms;
          -o-animation-delay: 0ms;
          animation-delay: 0ms;
        }
      }
    }
  }
}

@-webkit-keyframes ball-pulse {
  0%,
  60%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    opacity: 0.1;
    -webkit-transform: scale(0.01);
    transform: scale(0.01);
  }
}

@-moz-keyframes ball-pulse {
  0%,
  60%,
  100% {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
  30% {
    opacity: 0.1;
    -moz-transform: scale(0.01);
    transform: scale(0.01);
  }
}

@-o-keyframes ball-pulse {
  0%,
  60%,
  100% {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
  30% {
    opacity: 0.1;
    -o-transform: scale(0.01);
    transform: scale(0.01);
  }
}

@keyframes ball-pulse {
  0%,
  60%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  30% {
    opacity: 0.1;
    -webkit-transform: scale(0.01);
    -moz-transform: scale(0.01);
    -o-transform: scale(0.01);
    transform: scale(0.01);
  }
}
