@import "styles/variables";

mat-card.mat-mdc-card {
  --mdc-elevated-card-container-shape: 12px;
  --mdc-elevated-card-container-elevation: #{$shadow-gray};
  --mat-card-title-text-font: $font-family;
  color: $color-secondary;
  font-size: $fs-default;
  margin-bottom: 20px;

  .mat-mdc-card-header .mat-mdc-card-header-text .mat-mdc-card-title {
    font-size: $fs-default;
    font-weight: 600;
  }

  .mat-mdc-card-content {
    font-size: $fs-default;
    font-weight: 500;
  }

  &.primary {
    .mat-mdc-card-content {
      display: flex;
      justify-content: space-between;
    }
  }

  &.inner {
    --mdc-elevated-card-container-color: #{$color-background-light};
    --mdc-elevated-card-container-elevation: 0;

    mat-card-content {
      font-weight: 600;
      font-size: $fs-big;
      line-height: 23px;
    }
  }
}
