@import 'styles/variables';

mat-radio-group.radio-tabs {
  display: flex;

  mat-radio-button {
    flex-grow: 1;
    // todo: 17 angular TT-4148: check mdc-form-field if will be switched only to [mat-internal-form-field]
    .mdc-form-field {
      padding: 10px 5px;
      box-shadow: inset 0px -1px 0px 0px $color-text;
      justify-content: center;
      .mdc-radio {
        display: none;
      }

      .mdc-label {
        padding-left: 0;
        color: $color-text;
        font-weight: 500;
        line-height: 20px;
        font-size: 16px;
      }
    }

    &.mat-mdc-radio-checked {
      // todo: 17 angular TT-4148: check mdc-form-field if will be switched only to [mat-internal-form-field]
      .mdc-form-field {
        box-shadow: inset 0px -3px 0px 0px $color-primary;
        .mdc-label {
          font-weight: 700;
          color: $color-primary;
        }
      }
    }
  }
}
