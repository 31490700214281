@import "styles/form-variables";

@mixin hidden-scroll {
  overflow-x: scroll !important;
  -ms-overflow-style: none;
  /* IE и Edge */
  scrollbar-width: none;
  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin font-theme {
  font-size: $form-field-fs;
  line-height: $form-field-line-height;
  font-weight: $form-field-font-weight !important;
  font-family: $font-family;
}

@mixin background($bg, $bg-active: $bg) {
  background: $bg;

  &:active {
    background: $bg-active;
  }
}

@mixin transparent-color($color) {
  color: $color;
  background: rgba($color, .1);
}

@mixin font-style($font-size, $font-weight, $line-height) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

@mixin disable-text-selection {
  user-select: none; /* for new browsers */
  -webkit-user-select: none; /* for WebKit browsers */
  -moz-user-select: none; /* for Firefox */
  -ms-user-select: none; /* for Internet Explorer */
}
