// text decoration
.underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
  font-weight: normal !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

// cursor behavior
.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}
