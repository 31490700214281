@import 'styles/variables';
@import 'styles/breakpoints';

// common for all panels
.mat-expansion-panel-header-description {
  flex-grow: 1 !important;
}

.mat-expansion-panel {
  box-shadow: $shadow;
  border-radius: 10px;

  &.no-shadow {
    box-shadow: none !important;
  }

  &.checked {
    background-color: $background-success-section !important;
    border: 1px solid $color-primary;

    .mat-expansion-panel-header {
      background-color: inherit !important;

      .mat-expansion-indicator {
        position: relative;
      }
    }
  }

  // sidebar expansion panel (mobile)
  &.sidebar-expansion {
    box-shadow: none !important;
    background-color: transparent !important;

    .mat-expansion-toggle-indicator-after {
      padding: 10px;
      width: 180px;

      .mat-expansion-indicator {
        @include device-small {
          position: static !important;
        }

        &:after {
          content: "\e956";
          font-size: $fs-default;
          color: $color-black;
        }
      }
    }

    &.mat-expanded {
      background-color: $color-filled !important;
      .mat-expansion-indicator {
        transform: rotate(45deg) !important;

        &:after {
          color: $color-primary;
        }
      }
    }

    .mat-expansion-panel-content {
      background-color: white;

      .mat-expansion-panel-body {
        padding: 0;
      }
    }
  }

  > .mat-expansion-panel-header {
    > .mat-expansion-indicator {
      margin: 0 5px 0 0 !important;
      transform: rotate(225deg) !important;

      @include device-small {
        margin: 0 !important;
      }

      &:after {
        content: "\e906";
        font-family: 'icomoon' !important;
        border: none;
        font-size: 8px;
        color: $color-primary;
      }
    }

    &.compact {
      min-height: 40px !important;
      padding: 0 15px !important;
    }

    // White toggle color for panel header
    &.panel-toggle-white {
      > .mat-expansion-indicator {
        &:after {
          color: $color-white !important;
        }
      }
    }
  }

  &.hoverable-actions {
    .actions {
      display: none;
    }

    &:hover,
    &.mat-expanded {
      .actions {
        align-items: center;
        display: flex;
      }
    }
  }

  &.mat-expansion-panel-spacing {
    margin: 0 !important;
  }

  // Light grey background (for white main background)
  &.bg-light {
    background-color: $color-background-light;

    .mat-expansion-panel-header {
      &:hover {
        background: rgba($color-black, .02);
      }
    }

    .mat-expansion-panel-content {
      padding: 0 15px;
    }
  }

  &.body-p-static {
    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        position: static !important;
      }
    }
  }

  // White indicator color
  &.indicator-white {
    > .mat-expansion-panel-header {
      > .mat-expansion-indicator {
        &:after {
          color: $color-white;
        }
      }
    }
  }

  &.error {
    border: 1px solid $color-error;
  }

  &.success {
    border: 1px solid $color-success;
  }
}

.mat-expansion-panel.inner-panel {
  margin-bottom: 0 !important;
}

// default panel
.mat-expansion-panel:not(.table-row-panel):not(.sidebar-expansion) {
  margin-bottom: 25px;

  &[ng-reflect-hide-toggle="true"] {
    > .mat-expansion-panel-header {
      .mat-content {
        .mat-expansion-panel-header-title {
          @include device-small {
            padding-left: 0;
          }
        }
      }
    }
  }

  .mat-expansion-panel {
    margin-top: 10px;

    &:nth-child(1) {
      margin-bottom: 0 !important;
    }
  }

  > .mat-expansion-panel-header {
    min-height: 75px;
    padding: 15px;
    align-items: center;

    &.mat-expanded {
      .mat-expansion-indicator {
        transform: rotate(315deg) !important;
      }
    }

    @include device-small {
      min-height: 50px;
    }

    .mat-content {
      align-items: center;

      .mat-expansion-panel-header-title {
        color: $color-primary;
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;

        @include device-small {
          padding-left: 5px;
        }

        // panel title with icon
        i {
          font-size: 30px;
          line-height: 30px;
          margin-right: 15px;

          &:before {
            color: $color-primary;
          }
        }
      }

      .mat-expansion-panel-header-description {
        justify-content: flex-end;
        align-items: center;
        margin-right: 0;

        // Additional content like buttons, icons, e.t.c
        .description-content {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
        }

        .icons-group {
          display: flex;
          align-items: center;

          i.icon {
            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }

        i.icon {
          cursor: pointer;

          &:before {
            color: $color-text;
          }
        }

        .mat-mdc-form-field-subscript-wrapper {
          height: 0;
        }
        // Formfield in header description area
        mat-form-field.mat-mdc-form-field-type-mat-input {
          .mat-mdc-text-field-wrapper {
            .mat-mdc-form-field-flex {
              border: transparent;
              background-color: rgba($color-border, .2);
              margin-top: 0;
              .mat-mdc-form-field-icon-prefix {
                i {
                  &:before {
                    color: rgba(0, 0, 0, .2);
                  }
                }
              }
            }
          }
        }
      }
    }

    //.mat-expansion-indicator {
    //  @include device-small {
    //    left: 0px;
    //    position: absolute;
    //    top: 15px;
    //  }
    //}

    @include device-small {
      &.mat-expansion-toggle-indicator-before {
        .mat-content {
          margin-left: 10px;
        }
      }
    }
  }

  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      padding: 0;
      position: relative;

      .mat-expansion-panel {
        // Remove top space for inner pannels
        & + .mat-expansion-panel {
          margin-top: 0;
        }
      }
    }
  }

  & + .mat-expansion-panel {
    margin-top: 15px;
  }

  // Add left padding for subgroup
  &.with-subgroup {
    .mat-expansion-panel-header {
      padding-left: 40px;
      background-color: $color-white;
    }
  }

  // hover effect on main expansion panel
  &:not(.mat-expanded) .mat-expansion-panel-header:hover {
    &:not([aria-disabled='true']) {
      background: rgba(0, 0, 0, .02);
    }
  }
}

// panel inside summary table
.mat-expansion-panel.table-row-panel {
  border-bottom: 1px solid rgba($color-black, .12);

  .mat-expansion-panel-header {
    min-height: 40px;
    padding: 10px;
    align-items: center;
    background-color: $color-background-light;

    // for mobile panel indicator has position always top
    @include device-small {
      &.indicator-top {
        align-items: flex-start !important;

        .mat-expansion-indicator {
          margin-top: 10px !important;
        }
      }
    }

    &:not(.mat-expanded) {
      .mat-expansion-indicator {
        transform: rotate(225deg) !important;

        &:after {
          color: $color-text;
        }
      }
    }

    &.mat-expanded {
      .mat-expansion-indicator {
        transform: rotate(315deg) !important;

        &:after {
          color: $color-text;
        }
      }
    }

    .mat-content {
      align-items: center;

      .mat-expansion-panel-header-title {
        max-width: 35%;
        width: 100%;
        color: $color-text-dark;
        font-size: 12px;
        line-height: 14px;
        font-weight: bold;
      }

      .mat-expansion-panel-header-description {
        justify-content: flex-end;

        .summary-amount {
          color: $color-secondary;
          width: 200px;
          text-align: right;
        }
      }
    }
  }

  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      padding: 0;
    }
  }

  &:last-child {
    margin-bottom: 25px;
  }
}

// panel with description
.mat-expansion-panel.with-description {
  .mat-expansion-panel-header {
    .mat-content {
      .mat-expansion-panel-header-title {
        max-width: 100%;
        width: 100%;
        align-items: center;

        .header-info-wrapper {
          display: flex;
          flex-direction: column;

          .title {
            color: $color-primary;
            font-size: 18px;
            font-weight: bold;
          }

          .description {
            color: $color-secondary;
            font-size: 12px;
            font-weight: 600;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

// Panel with uncolored content if not expanded
.mat-expansion-panel.uncolored-by-default {
  .mat-expansion-panel-header {
    &:not(.mat-expanded) {
      .mat-content {
        .mat-expansion-panel-header-title {
          color: $color-text;

          .header-info-wrapper {
            .title {
              color: $color-text;
            }
          }

          i {
            &:before {
              color: $color-text;
            }
          }
        }
      }

      .mat-expansion-indicator {
        transform: rotate(225deg) !important;

        &:after {
          color: $color-text;
        }
      }
    }

    &.mat-expanded {
      .mat-content {
        .mat-expansion-panel-header-title {
          color: $color-primary;

          .header-info-wrapper {
            .title {
              color: $color-primary;
            }
          }

          i {
            &:before {
              color: $color-primary;
            }
          }
        }
      }
    }
  }
}

.mat-expansion-panel.d-flex {
  display: flex;
  flex-direction: column;
  height: 100%;

  .mat-expansion-panel-content {
    flex-grow: 1;

    .mat-expansion-panel-body {
      height: 100%;
    }
  }
}

.mat-accordion {
  mat-expansion-panel.mat-expansion-panel {
    box-shadow: none;
    margin: 0 !important;

    mat-expansion-panel-header.mat-expansion-panel-header {
      padding: 10px 0;

      &[aria-disabled="true"] {
        padding-left: 35px;

        @include device-small {
          padding-left: 20px;
        }
      }

      .mat-expansion-indicator::before {
        color: $color-gray-secondary !important;
      }

      @include device-small {
        padding-right: 15px;
      }
    }
  }
}

@include device-medium {
  .mat-expansion-panel:not(.table-row-panel) {
    > .mat-expansion-panel-header {
      .mat-content {
        .mat-expansion-panel-header-description {
          .description-content {
            flex-direction: column;
            align-items: flex-end;

            .icons-group {
              margin-top: 10px;
            }

            button {
              width: 100%;

              &:not(:last-child) {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@include device-small {
  .mat-expansion-panel,
  .mat-expansion-panel.table-row-panel {
    .mat-expansion-panel-header {
      .mat-content {
        flex-direction: column;
        align-items: flex-end !important;

        .mat-expansion-panel-header-title {
          max-width: 100%;
          width: 100%;
          margin-right: 0;
          align-items: flex-start;
        }

        .mat-expansion-panel-header-description {
          width: 100%;
          margin: 10px 0 0;
          align-items: center;
          position: relative;

          button {
            width: 100%;
          }

          .summary-amount {
            font-size: 14px;
          }
        }
      }
    }
  }

  .mat-expansion-panel {
    .mat-content {
      .mat-expansion-panel-header-title {
        .header-info-wrapper {
          .title {
            width: 90%;
            font-size: 14px !important;
          }

          .description {
            margin-top: 0;
          }
        }
      }
    }

    &:not(.table-row-panel) {
      &.with-subgroup {
        .mat-expansion-panel-header {
          padding-left: 20px;
        }
      }
    }
  }
}
