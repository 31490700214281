@import 'styles/variables';

a, .link {
  color: $color-primary;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.link {
  cursor: pointer;
  display: inline-block;
}
