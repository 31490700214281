@use 'styles/mixins';
@import 'styles/variables';
@import 'styles/form-variables';
@import 'form-field-invalid';
@import 'form-field-focused';
@import 'form-field-disabled';

mat-form-field.mat-mdc-form-field {
  --app-mat-field-flex-margin-top: 24px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-text-line-height: 18px;
  --app-mat-field-infix-margin-left: 10px;

  .mdc-text-field {
    padding: 0;
  }

  .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
    --mdc-filled-text-field-focus-active-indicator-height: 0;
    --mdc-filled-text-field-container-shape: 0;
  }

  .mat-mdc-text-field-wrapper {
    .mdc-line-ripple::before {
      border-bottom-width: 0 !important;
    }
  }

  &.no-border {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        align-items: center;
        border: none !important;
      }
    }
  }

  .mat-mdc-form-field-icon-prefix {
    left: 5px;
  }

  &:not(.mat-mdc-paginator-page-size-select) {
    width: 100%;

    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        align-items: center;
        border-radius: 3px;
        margin-top: var(--app-mat-field-flex-margin-top);
        background: $color-white;
        border: $form-field-border;

        .mat-mdc-form-field-icon-prefix {
          [matprefix] {
            font-size: 16px !important;

            &.sub-code {
              background-color: $color-white;
              border: 1px solid $color-border;
              border-radius: 4px;
              color: $color-text !important;
              font-size: 12px !important;
              font-weight: 600;
              padding: 10px 5px;
            }

            & + [matprefix] {
              margin-left: 5px;
            }

            &:not(.mat-button-wrapper) {
              color: $color-black;
            }
          }
        }

        .mat-mdc-form-field-icon-prefix,
        .mat-mdc-form-field-icon-suffix {
          align-items: center;
          color: $color-text;
          display: flex;
          padding: 0;

          i {
            &:not(.icon-dollar) {
              &:before {
                color: $color-primary;
              }
            }
          }
        }

        .mat-mdc-form-field-infix {
          border: none;
          position: static;
          padding-bottom: 0;
          min-height: 0;

          label.mat-mdc-floating-label {
            font-size: 14px;
            transform: none;
            top: 2px;
            left: 0;
            width: 100%;
            --mdc-filled-text-field-label-text-color: #{$color-deep-blue};

            mat-label {
              @include mixins.font-theme();
              // mat-tooltip near mat-label
              [mattooltip] {
                pointer-events: auto;
              }

              // inner button/link with action
              &.with-action {
                button {
                  height: auto;
                  min-width: auto;
                  font-size: 12px;
                  line-height: 15px;
                  font-weight: 600;
                  pointer-events: auto;

                  &[class*="icon-"] {
                    &:before {
                      left: 20px;
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }

          *[matsuffix] {
            color: $color-primary;
            position: absolute;
            right: 20px;
          }

          *[appinputcommaadder] {
            &:before {
              content: '$';
              color: $color-black;
              position: absolute;
            }
          }
        }
      }

      &:not(.mdc-text-field--outlined) {
        .mat-mdc-form-field-infix {
          padding-top: 0;
        }
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: flex;
      align-items: flex-start;

      .mat-mdc-form-field-error-wrapper {
        position: relative;
        padding: 0;
      }
    }

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      padding: 0;
    }

    &.compact {
      .mat-mdc-text-field-wrapper {
        --app-mat-field-flex-margin-top: 0;
        margin: 0;
      }

      // hide error message for compact form controls
      .mdc-floating-label {
        visibility: hidden;
      }
    }

    // custom class to remove the top space above the control
    &.no-label {
      .mat-mdc-text-field-wrapper {
        --app-mat-field-flex-margin-top: 0;
        margin-top: 0;
      }
    }
    // custom class to remove error field if we sure we don't need it
    &.no-error {
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }

    // In the case where we prevent the user from entering negative values and show him a prefix with a minus sign
    &.with-negative-prefix {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-icon-prefix {
            & + .mat-mdc-form-field-infix {
              margin-left: 0 !important;
            }
          }
        }
      }
    }
  }
  //this class adds asterisk. we need to paint it red
  .mdc-floating-label--required {
    &:after {
      color: $color-error;
    }
  }
}

.mat-mdc-icon-button .mat-mdc-button-touch-target {
  width: 30px !important;
}

.mat-mdc-form-field-infix {
  width: 175px !important;
}
