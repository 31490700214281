@import "../variables";
@import "../icomoon";

p-multiSelect {
  .p-multiselect {
    height: 38px;
    padding: 0;
    width: 100%;

    &.p-focus,
    &.p-multiselect-open {
      border-color: $color-primary !important;
      box-shadow: none !important;
    }

    .p-multiselect-label-container {
      .p-multiselect-label {
        line-height: 16px;
        padding: 11px 10px;
      }
    }

    .p-multiselect-trigger {
      .p-multiselect-trigger-icon {
        &.icon-caret-down {
          &:before {
            color: $color-gray-secondary;
          }
        }
      }
    }

    p-overlay {
      .p-overlay {
        background: transparent;
        margin: 10px 0;
        padding: 0;
        top: 100% !important;

        .p-overlay-content {
          .p-multiselect-panel {
            padding: 0;

            .p-multiselect-header {
              padding: 10px 10px 3px;
              border: none;

              .p-checkbox {
                width: 20px;

                .p-checkbox-box {
                  border: 2px solid $color-border;

                  &.p-highlight {
                    border-color: $color-primary;
                    background: $color-primary;

                    &:hover {
                      border-color: $color-primary;
                    }

                    .p-checkbox-icon {
                      color: $color-white;
                      &:before {
                        color: white;
                      }
                    }
                  }
                }
              }

              .p-multiselect-filter-container {
                background-color: $color-background-dark;
                border-radius: 4px;
                height: 24px;

                input {
                  background: none;
                  line-height: 16px;
                  padding-right: 0;
                  padding-left: 21px;
                  height: 100%;
                  font-size: 12px;
                }

                .p-multiselect-filter-icon {
                  left: 5px;
                  font-size: 12px;
                  margin-top: -6px;
                  width: 12px;

                  &:before {
                    @extend .icon-search;
                    color: $color-gray-secondary;
                  }
                }
              }

              button.p-multiselect-close {
                display: none;
              }
            }

            .p-multiselect-items-wrapper {
              max-height: 160px !important;
              ul.p-multiselect-items {
                padding: 0 0 10px;

                p-multiselectitem {
                  li.p-multiselect-item {
                    padding: 6px 10px;
                    color: $color-secondary;
                    box-shadow: none;
                    white-space: inherit;

                    &.p-highlight {
                      background: none;
                      color: $color-secondary;

                      &:hover {
                        background-color: $color-background-light;
                      }
                    }

                    &:hover {
                      background-color: $color-background-light;
                    }

                    .p-checkbox {
                      width: 20px;
                      height: 20px;
                      padding: 0;

                      .p-checkbox-box {
                        border: 2px solid $color-border;
                        border-radius: 2px;

                        &.p-highlight {
                          border-color: $color-primary;
                          background-color: $color-primary;
                        }

                        .p-checkbox-icon {
                          color: $color-white;
                          &:before {
                            color: white;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
