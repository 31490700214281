@import "variables";

.marker-popup {
  background-color: $color-white;
  font-size: $fs-small;
  font-weight: 500;
  padding: 5px;
  border-radius: 6px;
  box-shadow: $shadow;
  width: 230px;
  height: auto;
  max-height: 80px;
  overflow-y: auto;

  .popup-content {
    cursor: pointer;

    &:hover {
      background-color: $color-background;
    }
  }
}

.leaflet-popup-pane {
  .leaflet-popup.popup-cluster {
    position: absolute;
    top: 0;
    z-index: 200;
  }
}

.leaflet-marker-icon {
  cursor: pointer;
  position: absolute;

  .marker-content {
    span {
      position: absolute;
      left: 8px;
      top: 3px;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
