@import 'styles/variables';
@import 'styles/breakpoints';

app-data-card-group {
  .data-card-group {
    background-color: $color-background-light;
    border-radius: 12px;

    .g-0 {
      app-data-card {
        .data-card {
          position: relative;
        }
      }

      & > *:last-child {
        app-data-card {
          .data-card {
            &:before {
              display: none;
            }
          }
        }
      }
    }

    &.compact {
      app-data-card {
        .data-card {
          padding: 10px;
          display: flex;
          flex-direction: column-reverse;
          background-color: transparent;
          justify-content: center;

          .data-card-title {
            font-size: $fs-small;
            line-height: 12px;
            font-weight: bold;
            color: $color-text;
            margin-bottom: 0;

            .up-position {
              color: $color-success;
              background: rgb($color-success, 10%);
              border-radius: 12px;
            }
            .down-position {
              color: $color-error;
              background: rgb($color-error, 10%);
              border-radius: 12px;
            }
          }

          .data-card-value {
            font-size: $fs-big;
            line-height: 20px;
            margin-bottom: 5px;
          }

          .data-card-footer {
            display: none;
          }

          &:before {
            display: none;
          }
        }
      }
    }
  }
}

// new percentage indicator for data-card
.card-indicator {
  padding: 2px 4px;
  margin-left: 5px;
  border-radius: 12px;
  &.up-position {
    color: $color-success;
    background: rgb($color-success, 10%);
  }

  &.down-position {
    color: $color-error;
    background: rgb($color-error, 10%);
  }
}
