@import "styles/variables";

mat-radio-group.radio-tax-summary {
  display: flex;
  background-color: white;
  border-radius: 12px;
  padding: 10px;
  box-shadow: $shadow;

  mat-radio-button {
    padding: 10px 15px;
    border-radius: 12px;
    flex-grow: 1;

    &.mat-mdc-radio-checked {
      background-color: $color-primary-light;
    }

    .mdc-form-field {
      align-items: flex-start;
      display: flex;
      flex-direction: row;

      .mdc-label {
        flex-grow: 1;
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        padding-left: 8px;

        .row {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 10px;
          margin-left: -40px;
          font-weight: 600;
        }
      }
    }
  }
}
