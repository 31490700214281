@import 'styles/variables';

// compact tabs for charts/maps/etc
.tabs-icons {
  height: 100%;
  position: relative;
  padding-top: 40px;

  .mat-mdc-tab-header {
    position: absolute;
    right: 20px;
    z-index: 2;
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
    top: 15px;

    .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {
        .mat-mdc-tab-labels {
          background: $background-secondary;
          border-radius: 5px;

          .mat-mdc-tab {
            height: auto;
            padding: 5px;
            min-width: 24px;
            display: block;

            .mdc-tab__content {
              i {
                font-size: 16px;

                &:before {
                  color: $color-text;
                }
              }
            }

            &.mdc-tab-indicator--active {
              background: transparent;

              .mdc-tab__content {
                i:before {
                  color: $color-primary;
                }
              }
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }

        mat-ink-bar.mat-ink-bar {
          display: none;
        }
      }
    }

    .mat-mdc-tab-header-pagination {
      display: none;
    }
  }

  .mat-mdc-tab-body-wrapper {
    height: 100%;

    .mat-mdc-tab-body-content {
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }
}
