@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  mat-card.mat-mdc-card.primary {
    background-color: mat.get-theme-color($theme, primary, 50);
    border: 1px solid mat.get-theme-color($theme, primary, 500);
  }
}

@mixin typography($theme) {
  mat-card.mat-mdc-card.primary .mat-mdc-card-content {
    font: mat.get-theme-typography($theme, headline-3, font);
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
