@import "../variables";
@import "../buttons";

.p-confirm-popup {
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 20%);
  position: absolute;
  margin-top: 10px;
  top: 0;
  left: 0;
  border-radius: 6px;
  border: none;
  max-width: 350px;

  .p-confirm-popup-content {
    display: flex;
    align-items: center;
    padding: 15px;

    .p-confirm-popup-message {
      margin-left: 0;
      color: $color-secondary;
      font-size: $fs-default;
    }
  }

  .p-confirm-popup-footer {
    padding: 0 15px 15px;

    button.p-confirm-popup-reject {
      @extend button;
      @extend .link;
    }

    button.p-confirm-popup-accept {
      @extend button;
      @extend .primary;
    }

    button:focus {
      box-shadow: none !important;
    }
  }


  &:after,
  &:before {
    bottom: 100%;
    left: calc(var(--overlayArrowLeft, 0) + 1.25rem);
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-width: 8px;
    margin-left: -8px;
  }

  &:before {
    border-width: 10px;
    margin-left: -10px;
  }
}

.p-confirm-popup-flipped {
  margin-top: 0;
  margin-bottom: 10px;

  &:after,
  &:before {
    bottom: auto;
    top: 100%;
  }

  &:after {
    border-bottom-color: transparent;
  }

  &:before {
    border-bottom-color: transparent
  }
}


