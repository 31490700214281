@use 'styles/mixins' as mixins;
@import 'mixins';
@import 'styles/breakpoints';
@import 'styles/variables';

/*
  Internal tabs Location: The inner tabs are located inside the main workspace, under the lower tabs, in a separate section.
  Target: These tabs are designed for detailed navigation within the selected middle section,
   providing access to specific functions or data related to the current context.
  Using: Internal tabs are used to manage details or under functions related to a selected aspect of the middle section.
   For example, in the Expenses section, they can represent different types of expenses or cost categories.
 */
.tabs-inner {
  --app-tab-label-fs: #{$fs-big};
  --app-tab-label-fw: 500;
  --app-tab-label-line-heigth: 20px;
  @include tab-hover-bg(transparent !important);

  .mat-mdc-tab-header {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
    border-radius: 0;
    padding-right: 0;

    .mat-mdc-tab-header-pagination {
      display: none !important;
    }

    .mat-mdc-tab-label-container {
      @include mixins.hidden-scroll();

      .mat-mdc-tab-list {
        transform: none !important;

        .mat-mdc-tab {
          background: transparent;
          border-radius: 0 !important;
          font-size: 16px;
          padding: 5px 10px;
          text-transform: capitalize;
          @include tab-label-text-color($color-primary, $color-text, darken($color-text, 5%), darken($color-primary, 5%));

          &.mdc-tab-indicator--active {
            background: transparent !important;
            min-width: auto;
            .mdc-tab-indicator {
              // need to revert display: none for underline in this class
              display: flex !important;
            }
          }

          @include device-small {
            margin: 0 5px;
            min-width: auto;
            padding: 5px;
          }
        }
      }
    }
  }
}
