@import "variables";

@mixin statusBadgeColor($color) {
  border: 1px solid $color;
  background-color: rgba($color, .1);
  color: $color;
}

[class*=" status-badge-"], [class^="status-badge-"] {
  @include statusBadgeColor($color-border);
  font-size: $fs-extra-small;
  line-height: 9px;
  border-radius: 4px;
  padding: 3px 6px;
  text-transform: uppercase;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  display: inline-flex;
  align-items: center;
}

.status-badge-approved {
  @include statusBadgeColor($color-success);
}

.status-badge-rejected {
  @include statusBadgeColor($color-error);
}

.status-badge-pending {
  @include statusBadgeColor($color-warning);
}

.status-badge-info {
  @include statusBadgeColor($color-primary);
}

.status-badge-secondary {
  @include statusBadgeColor($color-secondary);
}

.status-badge-text {
  @include statusBadgeColor($color-secondary);
  color: $color-white;
}

.rounded {
  border-radius: 11px;
  font-weight: 700;
  font-size: $fs-small;
  line-height: 15px;
  padding: 2px 4px;
}

.error-badge {
  border-radius: 4px;
  color: $color-white;
  background-color: $color-error;
  font-weight: 700;
  font-size: $fs-small;
  padding: 5px 25px;
}

.empty-chart-message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  color: $color-secondary;
}

* {
  outline: none !important;
}

.has-notification {
  position: relative;

  &:after {
    content: '';
    background-color: $color-error;
    position: absolute;
    right: -5px;
    top: -5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}

app-progress-bar {
  &:last-of-type {
    .bar {
      margin-bottom: 0;
    }
  }
}
