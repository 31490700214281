@import 'styles/variables';

.mat-mdc-list-base .mat-mdc-list-item {
  --mdc-list-list-item-hover-state-layer-color: #{$color-primary-light};
  height: auto !important;
  padding-left: 0;

  &.selected .mdc-list-item__content {
    background-color: $color-primary-light;
    border-color: $color-primary;
  }

  .mdc-list-item__content {
    border-left: 1px solid $color-border;
    padding-left: 16px;

    .mat-mdc-list-item-unscoped-content {
      display: flex;
      flex: auto;
      align-items: center;
    }

    a {
      color: $color-secondary;
      width: 100% !important;
      font-size: $fs-big;
    }
  }

  &:hover {
    background-color: $color-primary-light;
    border-color: $color-primary;
  }
}
