@use 'styles/mixins' as mixins;
@import 'styles/variables';
@import 'styles/breakpoints';
@import 'mixins';
@import 'primary';
@import 'secondary';
@import 'tabs-avatar';
@import 'tabs-icons';
@import 'tabs-inner';

:root {
  --app-tab-label-fs: #{$fs-small};
  --app-tab-label-fw: 600;
  --app-tab-label-line-heigth: 15px;
}

// common styles for other modification classes for mat-mdc-tab-group except tabs-icons,tabs-avatar
nav.mat-mdc-tab-nav-bar.mat-mdc-tab-header,
.mat-mdc-tab-group {
  overflow: hidden;

  &.primary,
  &.secondary,
  &.tabs-inner {
    border: none;
    padding: 0;
    background-color: transparent;

    .mdc-tab__text-label {
      @include mixins.font-style(var(--app-tab-label-fs), var(--app-tab-label-fw), var(--app-tab-label-line-heigth));
    }

    .mat-mdc-tab-labels,
    .mat-mdc-tab-links {
      //prevents box-shadow to cut
      padding-bottom: 10px;
    }

    .mat-mdc-tab-link-container,
    .mat-mdc-tab {
      overflow: hidden;
      font-family: $font-family;
      min-width: auto !important;
      opacity: 1;
      @include mixins.disable-text-selection();

      swiper {
        // overflow and height prevents box-shadow to cut
        overflow: visible;
      }

      @include device-x-small {
        font-size: 10px;
        line-height: 15px;
        min-width: 100px;
      }
    }

    // underline
    .mat-ink-bar {
      display: none;
    }

    .mat-mdc-tab-list {
      width: 100%;
    }

    // pagination controls
    &.mat-mdc-tab-header-pagination-controls-enabled {
      display: flex;

      .mat-mdc-tab-header-pagination {
        box-shadow: none;
      }
    }

    .mat-mdc-tab-body-wrapper {
      .mat-mdc-tab-body {
        .mat-mdc-tab-body-content {
          overflow-x: hidden;
        }
      }
    }
  }

  &.primary,
  &.secondary {
    .mat-mdc-tab-header {
      background-color: transparent !important;
      box-shadow: none !important;
      border: none !important;
      border-radius: 0;
      padding-right: 0;

      .mat-mdc-tab-label-container {
        @include mixins.hidden-scroll();
      }
    }

    .mdc-tab {
      box-shadow: 0 4px 14px 0 #00000014;
      height: 30px;
      opacity: 1;
      margin-right: 10px;
      /* The min-content sizing keyword represents the intrinsic minimum width of the content.
         For text content this means that the content will take all soft-wrapping opportunities,
         becoming as small as the longest word. */
      min-width: min-content;
      text-transform: capitalize;
    }
  }

  .mdc-tab-indicator--active {
    //// active tab can't be clicked, remove cursor
    cursor: default;
  }
}

//other general styles
.mat-mdc-tab-header {
  border-bottom: none !important;
  // This is the underline for the active tab. We're using 'button' like visualisation for that
  .mdc-tab-indicator {
    display: none;
  }
}

// to center the label in tab
.mdc-tab.mat-mdc-tab,
.mat-mdc-tab-link {
  align-items: center;
  display: flex;
}

.mat-mdc-dialog-content {
  .mat-mdc-tab-body-wrapper {
    @include device-small {
      overflow-y: visible;
    }
  }
}

.mat-mdc-tab-group {
  // to hide header if only 1 tab
  .mat-mdc-tab {
    &:only-child {
      display: none !important;
    }
  }
}

//nav only styles
nav.mat-mdc-tab-nav-bar.mat-mdc-tab-header {
  display: inline-grid;
}

// to display all nav tabs in one screen
app-navigation {
  .navigation-carousel {
    .swiper-slide {
      flex: 1 1 100%;

      .mat-mdc-tab-link {
        width: auto;
      }
    }
  }

  .mat-mdc-tab-links {
    display: block !important;

    .mat-mdc-tab-link {
      width: auto;
    }
  }
}
