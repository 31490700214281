@use 'styles/breakpoints' as breakpoints;
@import 'styles/variables';

mat-paginator {
  font-family: $font-family;
  font-size: $fs-small !important;
  font-weight: 500 !important;
  line-height: 14px !important;
  color: $color-deep-blue !important;

  .mat-mdc-paginator-page-size {
    margin-right: 10px;
  }

  .mat-mdc-paginator-range-label {
    margin: 0;
  }
  // haven't ripple effect on design
  .mat-mdc-button-persistent-ripple {
    display: none;
  }
  // notch has fuzzy logic and contains of 3 elements instead of 1 as before
  // we just need a border around the select and we make it in .mat-mdc-select
  .mdc-notched-outline {
    display: none;
  }

  .mat-mdc-icon-button {
    height: 26px !important;
    margin-left: 5px;
    padding: 0 !important;
    width: 26px !important;

    .mat-mdc-button-touch-target {
      height: 26px;
      width: 26px;
    }
  }

  .mat-mdc-paginator-range-actions {
    margin-right: 10px;
  }

  .mat-mdc-form-field {
    align-content: center;
    width: auto;

    .mat-mdc-form-field-flex {
      width: fit-content;

      .mat-mdc-form-field-infix {
        min-height: 32px !important;
        padding: 0 !important;
        width: fit-content !important;
      }
    }
  }

  mat-select.mat-mdc-select {
    border: 1px solid $color-border-secondary;
    border-radius: 3px;
    padding: 7px 8px !important;

    .mat-mdc-select-value {
      height: 14px;
      line-height: 14px;
      margin-right: 4px;
      width: 22px;
    }
  }
}

@include breakpoints.device-x-small {
  .mat-mdc-paginator-container {
    flex-direction: column;
    margin-top: 5px;
  }

  .mat-mdc-paginator-page-size {
    order: 1;
    margin-bottom: 10px;
  }

  .mat-mdc-paginator-range-actions {
    order: 2;
  }

  .mat-mdc-paginator-container > div {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
