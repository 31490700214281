@use '@angular/material' as mat;
@use 'mixins' as mixins;
@import 'styles/variables';
@import 'styles/breakpoints';
@import 'checkbox-select-all';
@import "styles/form-controls/checkbox";
@include mat.checkbox-density(-1);

//TT-4127 @taxtankit
mat-checkbox.mat-mdc-checkbox {
  --mdc-checkbox-state-layer-size: 0px;

  @include mixins.checkbox-theme($checkbox-outline-color, $checkbox-outline-color, $checkbox-bg-color);
  //todo: return in 17 angular TT-4148
  //[mat-internal-form-field] {
    align-items: center;

    .mdc-checkbox {
      width: 20px;
      height: 20px;
      margin-right: 4px;

      &:hover {
        .mdc-checkbox__ripple {
          display: none;
        }
      }

      &:active {
        border-color: initial;
      }

      .mdc-checkbox__ripple {
        .mat-ripple-element {
          background-color: $color-primary;
        }
      }

      .mdc-checkbox__background {
        position: static;
        width: 20px;
        height: 20px;
        border-radius: $checkbox-border-radius;
        border: $checkbox-unchecked-border-width solid var(--mdc-theme-secondary);
        border-color: #{$checkbox-outline-color};
        @include mixins.checkbox-theme($checkbox-outline-color, $checkbox-bg-color, $checkbox-bg-color);
        svg.mdc-checkbox__checkmark {
          background-color: $checkbox-bg-color;
          border: 2px solid;
          width: 16px;
          height: 16px;
          border-radius: 0;
          margin: auto;
          path.mdc-checkbox__checkmark-path {
            stroke: $color-primary;
            stroke-width: 4px;
          }
        }
      }
    }

    .mdc-label {
      font-size: 14px;
      line-height: 18px;
      color: $color-text-dark;
      font-weight: 500;
      white-space: normal;

      @include device-small {
        font-size: $fs-small;
      }

      a {
        color: $color-primary;
        text-decoration: none;
      }
    }
  //}

  &.mat-mdc-checkbox-checked {
    //todo: return in 17 angular TT-4148
    //[mat-internal-form-field] {
      .mdc-checkbox {
        .mdc-checkbox__background {
          @include mixins.checkbox-theme($color-primary, $color-primary, $checkbox-bg-color);
          border: $checkbox-checked-border !important;
          svg.mdc-checkbox__checkmark {
            path.mdc-checkbox__checkmark-path {
              stroke: $color-primary;
            }
          }
        }
      }
    //}
  }

  &.mat-mdc-checkbox-disabled {
    //todo: return in 17 angular TT-4148
    //[mat-internal-form-field] {
      .mdc-checkbox {
        .mdc-checkbox__background {
          background-color: $checkbox-disabled-bg-color !important;
          border: $checkbox-unchecked-border !important;
          @include mixins.checkbox-theme($checkbox-outline-color, $checkbox-disabled-bg-color, $checkbox-disabled-bg-color);
          svg.mdc-checkbox__checkmark {
            background-color: $checkbox-disabled-bg-color;
            border: 2px solid $checkbox-disabled-bg-color;
            path.mdc-checkbox__checkmark-path {
              stroke: $checkbox-outline-color !important;
            }
          }
        }
      }
    //}
  }

  &.ng-touched.ng-invalid {
    //todo: return in 17 angular TT-4148
    //[mat-internal-form-field] {
      .mdc-checkbox {
        .mdc-checkbox__background {
          @include mixins.checkbox-theme($color-error, $color-error, $checkbox-bg-color);
          svg.mdc-checkbox__checkmark {
            path.mdc-checkbox__checkmark-path {
              stroke: $color-error;
            }
          }
        }
      }
    //}
  }
}

