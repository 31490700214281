@use 'styles/mixins';
@import "styles/variables";
@import "styles/form-variables";
p-password {
  &.ng-invalid.ng-touched {
    .p-password {
      border-color: $color-error;
    }

    label {
      color: $color-error;
    }

    .error-messages {
      color: $color-error;
      position: absolute;
      top: 45px;
      visibility: visible;
      width: 100%;
      z-index: 1;
    }

    .p-input-icon-right > i {
      &:before {
        color: $color-error;
      }
    }

  }

  &.ng-valid.ng-dirty {
    &.p-inputwrapper-focus {
      .p-password {
        border-color: $color-primary !important;
      }

      label {
        color: $color-primary;
      }

      .p-input-icon-right > i {
        &:before {
          color: $color-primary;
        }
      }
    }
  }
}

.p-password-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-bottom: 20px;
  position: relative;

  .panel-css {
    margin-top: 10px !important;
    background-color: red !important;
  }

  .error-messages {
    height: 0;
    visibility: hidden;
  }

  label {
    @include mixins.font-theme();
    color: $color-deep-blue;
    left: 0;
    padding: 0;
    position: absolute;
    top: -25px;
  }
}
.p-password-panel {
  position: absolute;
  top: 40px;
  margin-top: 5px!important;
  right: 0;
  width: 100%;
  z-index: 1;

  .p-password-meter {
    height: 10px;

    .p-password-strength {
      height: 100%;
      transition: width 1s ease-in-out;
      width: 0;
      &.weak {
        background: $color-error;
      }

      &.medium {
        background: $color-warning;
      }

      &.strong {
        background: $color-success;
      }
    }
  }
}
