@import "variables";

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1 {
  font-size: 32px;
  line-height: 39px;
  color: $color-secondary;
}

h2 {
  font-size: 28px;
  line-height: 34px;
  color: $color-primary;
}

h3 {
  font-size: 24px;
  line-height: 29px;
  color: $color-primary;
}

h4 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: $color-secondary;
}

h5 {
  font-size: 18px;
  line-height: 22px;
  color: $color-primary;
}

h6 {
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: $color-text;
}

@media (max-width: 575px) {
  h1 {
    font-size: 24px;
    line-height: 29px;
  }

  h2 {
    font-size: 22px;
    line-height: 24px;
  }

  h3 {
    font-size: 18px;
    line-height: 19px;
  }

  h4 {
    font-size: 14px;
    line-height: 14px;
  }

  h5 {
    font-size: 12px;
    line-height: 12px;
  }

  h6 {
    font-size: 8px;
    line-height: 8px;
  }
}

// new styles
.h1, .h2, .h3 {
  color: $color-secondary;
  font-weight: 700;
}
.h1 {
  font-size: 20px;
  margin-bottom: 15px;
}

.h2 {
  font-size: $fs-extra-big;
  margin-bottom: 10px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.h3 {
  font-size: $fs-big;
}

h4 {
  font-weight: 600;
  font-size: $fs-big;
  line-height: 23px;
}

// TODO: @taxtankit rename after redesign
//  https://www.figma.com/design/bXotb0PP6hCe1NKU0tnHS7/Style-Guide-v2?node-id=183-661&node-type=SECTION&t=ikLLS3Tb7e1Bm55W-0
.h7 {
  font-weight: 600;
  font-size: $fs-small;
  line-height: 15px;
  color: $color-gray-secondary;
  margin-bottom: 5px;
}

.h8 {
  font-size: $fs-default !important;
  line-height: 18px !important;
  font-weight: 500 !important;
}
