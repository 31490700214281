@import 'styles/variables';
@import 'styles/breakpoints';
@import 'budget-radio-group';
@import 'radio-icons';
@import 'radio-tabs';
@import 'radio-tax-summary';

mat-radio-group.mat-mdc-radio-group:not(.radio-icons):not(.default):not(.radio-tabs) {
  height: 40px;
  border-radius: 4px;
  border: 1px solid $color-border-secondary;
  background-color: $color-white;
  padding: 3px;
  display: flex;
  margin-bottom: 15px;

  mat-radio-button.mat-mdc-radio-button {
    // @TODO 4521 vik
    flex: 1 1 100%;
    border-radius: 4px;
    padding: 0 5px;
    // todo: 17 angular TT-4148: check mdc-form-field if will be switched only to [mat-internal-form-field]
    .mdc-form-field {
      height: 100%;
      white-space: normal;

      .mdc-radio {
        display: none;
      }

      .mdc-label {
        color: $color-text;
        text-align: center;
        width: 100%;
        padding: 0;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;

        i.icon {
          font-size: 40px;
          margin-right: 18px;

          &:before {
            color: $color-primary;
          }
        }

        .info {
          color: $color-text-dark;

          .caption {
            font-size: 18px;
            font-weight: 600;
          }

          .description {
            font-size: 12px;
            font-weight: 500;
            color: $color-text;
          }
        }
      }
    }

    &.mat-mdc-radio-checked {
      background-color: $color-primary;
      // todo: 17 angular TT-4148: check mdc-form-field if will be switched only to [mat-internal-form-field]
      .mdc-form-field {
        .mdc-label {
          color: $color-white;

          i.icon {
            &:before {
              color: $color-white;
            }
          }

          .info {
            .caption {
              color: $color-white;
            }

            .description {
              color: $color-white;
            }
          }
        }
      }
    }

    &.with-icon {
      // todo: 17 angular TT-4148: check mdc-form-field if will be switched only to [mat-internal-form-field]
      .mdc-form-field {
        .mdc-label {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 20px;
          color: $color-text-dark;

          .info {
            text-align: left;
          }
        }
      }
    }
  }

  // mat-menu inside radio-group
  &.empty {
    height: 0;
    padding: 0;
    border: none;
    margin-bottom: 0;
  }

  // primary radio buttons
  &.primary {
    background-color: transparent;
    border: none;
    height: 30px;
    padding: 0;

    mat-radio-button.mat-mdc-radio-button {
      border-radius: 5px;
      display: flex;
      margin-right: 10px;
      padding: 5px 15px;
      &:not(.mat-mdc-radio-checked) {
        &.mat-accent {
          background: white;
        }
      }
      // todo: 17 angular TT-4148: check mdc-form-field if will be switched only to [mat-internal-form-field]
      .mdc-form-field {
        white-space: nowrap;
      }
    }
  }
  // todo: TT-4676 refactor taxtankit
  &.compact {
    mat-radio-button.mat-mdc-radio-button {
      flex: 0;
    }
  }
}

mat-radio-group {
  .mdc-form-field {
    width: 100%;
    .mdc-label {
      align-content: center;
      height: 100%;
    }
  }

  mat-radio-button.mat-mdc-radio-button .mdc-radio {
    padding: 0;
    background: $color-white;
    border-radius: 50%;
    --mdc-radio-unselected-icon-color: #{$color-border-secondary};
    --mdc-radio-disabled-unselected-icon-color: #{$color-border-secondary};
    --mdc-radio-disabled-unselected-icon-opacity: 1;
    --mdc-radio-disabled-selected-icon-color: #{$color-border-secondary};
    --mdc-radio-disabled-selected-icon-opacity: 1;
    --mdc-radio-unselected-focus-icon-color: #{$color-border-secondary};
    &:hover {
      --mdc-radio-unselected-hover-icon-color: #{$color-border-secondary};
    }

    .mat-mdc-radio-touch-target {
      display: none !important;
    }
  }

  .mdc-form-field>label {
    cursor: pointer;
    padding-left: 8px;
  }

  &.mat-mdc-radio-group.ng-touched.ng-invalid {
    mat-radio-button.mat-mdc-radio-button {
      // todo: 17 angular TT-4148: check mdc-form-field if will be switched only to [mat-internal-form-field]
      .mdc-form-field {
        color: $color-error;
        .mdc-radio {
          .mdc-radio__outer-circle {
            border-color: $color-error;
          }
        }
      }
    }
  }
}

mat-radio-group.full-width {
  width: 100%;

  .mat-mdc-radio-button {
    .mdc-label {
      width: 100%;
    }
  }
}

@media (max-width: 575px) {
  mat-radio-group.mat-mdc-radio-group {
    height: auto;
    mat-radio-button.mat-mdc-radio-button {
      &.with-icon {
        // todo: 17 angular TT-4148: check mdc-form-field if will be switched only to [mat-internal-form-field]
        .mdc-form-field {
          .mdc-label {
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            padding: 10px;

            .info {
              text-align: center;
            }

            i.icon {
              font-size: 20px;
              margin: 0 0 10px 0;
            }
          }
        }
      }
    }

    &:not(.radio-icons) {
      mat-radio-button.mat-mdc-radio-button {
        // todo: 17 angular TT-4148: check mdc-form-field if will be switched only to [mat-internal-form-field]
        .mdc-form-field {
          .mdc-label {
            font-size: 10px;
          }
        }
      }
    }
  }
}
