@import "styles/variables";

.p-datatable-table {
  border-collapse: initial;

  .p-datatable-tbody > tr {
    background: $color-white;
  }

  th, td {
    color: $color-text-dark;
    font-size: $fs-small;
    font-weight: 500;
    line-height: 14px;
    padding: 16px 10px;

    &:nth-child(n+2) {
      text-align: right !important;
    }
  }
}

.p-datatable .p-sortable-column .p-icon-wrapper {
  display: inline-flex;
}

.p-column-filter-menu-button {
  height: auto;
}

.p-datatable-thead {
  th {
    background: $color-background-light;
  }
}

.table-align-left {
  .p-datatable td:nth-child(n+2), .p-datatable th:nth-child(n+2) {
    text-align: left !important;
  }
}
