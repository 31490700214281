@import 'styles/breakpoints';
// for sub tables
.mat-mdc-table,
table {
  .mat-mdc-row {
    &.expandable-row {
      min-height: 0;
      border-top: 0 !important;

      &.mat-mdc-row {
        min-height: 0;
      }

      .mat-mdc-cell:first-child {
        text-align: initial;
        padding: 0 !important;
        overflow-y: hidden;
        // to remove border in mat-cell and add it to first child because it adds unnecessary line
        border-top: 0 !important;

        .element-detail {
          width: 100%;
          overflow: hidden;
          display: flex;

          &.collapsed {
            display: none;
          }
        }

        .mat-mdc-table.inner-table {
          .mat-mdc-cell,
          .mat-mdc-header-cell {
            &:first-of-type {
              padding-left: 40px !important;
              @include device-small {
                padding: var(--app-cell-padding) !important;
              }
            }
          }
        }

        @include device-small {
          padding-left: 0 !important;
        }
      }
    }
  }
}
