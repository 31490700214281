@import "styles/variables";
@import "styles/form-variables";

.p-inputwrapper {
  &.ng-touched.ng-invalid:not(.p-inputwrapper-focus) {
    border-color: $color-error !important;

    &:hover {
      border-color: $color-error !important;
    }
  }

  &.ng-touched.ng-invalid + label {
    color: $color-error !important;
  }
}

.p-inputwrapper {
  &.p-inputwrapper-focus {
    border-color: $color-primary !important;

    &:hover {
      border-color: $color-primary !important;
    }
  }

  &.p-inputwrapper-focus + label {
    color: $color-primary !important;
  }
}

p-inputnumber,
p-multiSelect .p-multiselect,
.p-password {
  border-radius: $form-field-border-radius;
  border: $form-field-border;
}

p-inputnumber,
.p-password {
  overflow: hidden;
}
