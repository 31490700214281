@import "styles/variables";

// Default border styles

.bordered {
  border: 1px solid $color-border !important;
}

.bordered-t {
  border-top: 1px solid $color-border !important;
}

.bordered-b {
  border-bottom: 1px solid $color-border !important;
}

.bordered-r {
  border-right: 1px solid $color-border !important;
}

.bordered-l {
  border-left: 1px solid $color-border !important;
}

.bordered-v {
  border-top: 1px solid $color-border !important;
  border-bottom: 1px solid $color-border !important;
}

.bordered-h {
  border-right: 1px solid $color-border !important;
  border-left: 1px solid $color-border !important;
}

.bordered-none {
  border: none !important;
}

.border-radius {
  border-radius: 4px !important;

  &-top {
    border-radius: 4px 4px 0 0;
  }

  &-bottom {
    border-radius: 0 0 4px 4px;
  }

  &-left {
    border-radius: 4px 0 0 4px;
  }

  &-right {
    border-radius: 0 4px 4px 0;
  }
}

// 50% border radius
.border-radius-50 {
  border-radius: 50% !important;
}

.border-radius-default {
  border-radius: 12px !important;
}
