@use 'styles/mixins' as mixins;
@import 'styles/variables';
@import 'styles/breakpoints';
@import 'progress-spinner';

.mat-mdc-select.badge-select {
  display: flex;
  padding: 0 5px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  font-size: 12px;
  font-weight: 500;
  height: 20px;
  width: auto;

  &.warning {
    background-color: rgba($color-warning, .1);
    border-color: $color-warning;

    .mat-mdc-select-value {
      color: $color-warning;
    }

    .mat-mdc-select-arrow {
      border-top-color: $color-warning;
      color: $color-warning !important;
      margin: 0 0 0 5px;
    }
  }
}

.mat-mdc-select-panel, .mat-mdc-autocomplete-panel {
  --mat-option-selected-state-label-text-color: #{$color-deep-blue};
  --mat-option-label-text-color: #{$color-deep-blue};
  --mat-optgroup-label-text-color: #{$color-text};
  --mat-option-hover-state-layer-color: #{$color-filled};
  --mat-option-focus-state-layer-color: #{$color-filled};
  --mat-option-selected-state-layer-color: #{$color-filled};
  --mat-option-label-text-size: #{$fs-default};
  --mat-option-label-text-line-height: 14px;

  .mat-mdc-optgroup-label {
    font-family: $font-family !important;
    font-weight: 500;
    font-size: $fs-small;
    line-height: 14px !important;
  }

  .mat-mdc-option {
    height: auto !important;
    font-family: $font-family !important;
    font-weight: 500;
    min-height: 48px;
    line-height: 18px !important;
  }

  .mdc-list-item__primary-text {
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      white-space: normal !important;
    }
  }
}

.link-styled-select {
  &.mat-mdc-select {
    padding-left: 0;
    width: auto;

    .mat-mdc-select-value {
      border-bottom: 1px solid $color-secondary;
      color: $color-secondary;
      font-size: 16px;
      font-weight: 700;
    }
  }
  // we don't need to see the checkmark in this class
  .mat-mdc-option-pseudo-checkbox.mat-pseudo-checkbox-checked {
    opacity: 0;
  }
}

@media (max-width: 575px) {
  // options group
  mat-optgroup.mat-mdc-optgroup {
    .mat-mdc-option {
      span {
        font-size: $fs-default;
      }
    }
  }
}
