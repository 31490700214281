@import 'styles/variables';

mat-radio-group.radio-icons {
  display: flex;

  mat-radio-button.mat-mdc-radio-button {
    margin-right: 5px;
    background-color: $color-white;
    border-radius: 50%;

    &:last-child {
      margin-right: 0;
    }

    // todo: 17 angular TT-4148: check mdc-form-field if will be switched only to [mat-internal-form-field]
    .mdc-form-field {
      position: relative;
      .mdc-radio {
        width: 32px;
        height: 32px;

        .mdc-radio__native-control {
          display: none;
        }

        .mdc-radio__outer-circle {
          width: 32px;
          height: 32px;
          border-width: 1px;
          border-color: $color-border-secondary;
          --mdc-radio-selected-icon-color: #{$color-primary};
          --mdc-radio-unselected-icon-color: #{$color-inactive};
        }

        .mdc-radio__inner-circle {
          display: none;
        }
      }

      .mdc-label {
        padding: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        i.icon {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          &:before {
            font-size: 16px;
            color: $color-border-secondary;
          }
        }

        .info {
          .caption {

          }

          .description {

          }
        }
      }
    }

    &.mat-mdc-radio-checked {
      // todo: 17 angular TT-4148: check mdc-form-field if will be switched only to [mat-internal-form-field]
      .mdc-form-field {
        .mdc-radio {
          .mdc-radio__outer-circle {
            border-color: $color-primary;
          }

          .mdc-radio__inner-circle {
            display: none;
          }
        }

        .mdc-label {


          i.icon {
            &:before {
              color: $color-primary;
            }
          }

          .info {
            .caption {

            }

            .description {

            }
          }
        }
      }
    }
  }

  .mdc-radio__native-control:focus+.mdc-radio__background::before {
    opacity: 0;
  }

  &.ng-touched.ng-invalid {
    mat-radio-button {
      // todo: 17 angular TT-4148: check mdc-form-field if will be switched only to [mat-internal-form-field]
      .mdc-form-field {
        .mdc-radio {
          .mdc-radio__outer-circle {
            border-color: $color-error !important;
          }
        }

        .mdc-label {
          i:before {
            color: $color-error !important;
          }
        }
      }
    }
  }
}
