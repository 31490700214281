@import "variables";

// @TODO Artem: remove all prime-icons (.pi) from this file

.fa:before {
  color: $color-text-dark;
  font-family: 'Font Awesome 5 Free' !important;
}

[class^="icon-"],
[class*=" icon-"],
.pi {
  &:before {
    // default color
    color: $color-text-dark;
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.icon-tip:before,
.pi-info-circle:before {
  content: "\e972";
}
.icon-rules:before {
  content: "\e966";
}
.icon-reload:before {
  content: "\e963";
}
.icon-aircondition:before {
  content: "\e91c";
}
.icon-alert:before {
  content: "\e91d";
}
.icon-building-improvements:before {
  content: "\e91e";
}
.icon-calculator:before {
  content: "\e91f";
}
.icon-cart:before {
  content: "\e920";
}
.icon-check-bordered:before {
  content: "\e921";
}
.icon-cocktail:before {
  content: "\e922";
}
.icon-dishwasher:before {
  content: "\e923";
}
.icon-downgrade:before {
  content: "\e924";
}
.icon-equipment:before {
  content: "\e925";
}
.icon-gas:before {
  content: "\e926";
}
.icon-info:before,
.pi-exclamation-triangle:before {
  content: "\e96c";
}
.icon-lightbulb:before {
  content: "\e928";
}
.icon-loan:before {
  content: "\e929";
}
.icon-lock-repeat:before {
  content: "\e92a";
}
.icon-love:before {
  content: "\e92b";
}
.icon-lvp:before {
  content: "\e92c";
}
.icon-oven:before {
  content: "\e92e";
}
.icon-plant-equipment:before {
  content: "\e930";
}
.icon-plus:before {
  content: "\ed71";
}
.icon-close,
.pi-times {
  background: transparent !important;
  box-shadow: none !important;
  &:before {
    content: "\e931";
  }
}
.icon-portfolio:before, .icon-work:before {
  content: "\e932";
}
.icon-reminder:before {
  content: "\e933";
}
.icon-reset-password:before {
  content: "\e934";
}
.icon-thank-you:before {
  content: "\e935";
}
.icon-thumb-up:before {
  content: "\e936";
}
.icon-tv:before {
  content: "\e937";
}
.icon-upgrades:before {
  content: "\e938";
}
.icon-upload-cloud:before {
  content: "\e939";
}
.icon-view:before {
  content: "\e93a";
}
.icon-washing:before {
  content: "\e93b";
}
.icon-at:before {
  content: "\e93d";
}
.icon-amex:before {
  content: "\e93e";
}
.icon-attachment:before {
  content: "\e940";
}
.icon-bell:before {
  content: "\e944";
}
.icon-chart-trend:before {
  content: "\e946";
}
.icon-check:before,
.pi-check:before {
  content: "\e90e";
}
.icon-contact:before {
  content: "\e947";
}
.icon-doc-pdf:before {
  content: "\e94a";
}
.icon-doc:before {
  content: "\e94b";
}
.icon-holding-tank:before, .icon-holding:before {
  content: "\e97b";
}
.icon-chat-circle:before {
  content: "\e976";
}

.icon-play-circle:before {
  content: "\e96b";
}

.icon-book-open:before {
  content: "\e96a";
}

.icon-doc-check {
  position: relative;
  @extend .icon-doc;

  // @TODO make universal subclass for additional icons (check, times, info, etc.)
  &:after {
    background-color: white;
    border-radius: 50%;
    bottom: 10px;
    content: "\f058";
    color: $color-success;
    font-family: 'Font Awesome 5 Free' !important;
    font-weight: 900;
    font-style: normal;
    font-size: .7em;
    right: 5px;
    position: absolute;
  }
}

.icon-dollar:before {
  content: "\e94c";
}
.icon-exchange:before {
  content: "\e94f";
}
.icon-filter:before {
  content: "\e950";
}
.icon-folder:before {
  content: "\e951";
}
.icon-greater:before {
  content: "\e952";
}
.icon-group:before {
  content: "\e953";
}
.icon-image-jpg:before {
  content: "\e955";
}
.icon-less:before {
  content: "\e957";
}
.icon-link:before {
  content: "\e95a";
}
.icon-lock:before {
  content: "\e913";
}
.icon-mastercard:before {
  content: "\e95b";
}
.icon-payed:before {
  content: "\e95e";
}
.icon-property-income:before {
  content: "\e961";
}
.icon-revert:before {
  content: "\e964";
}
.icon-safety:before {
  content: "\e967";
}
.icon-save:before {
  content: "\e968";
}
.icon-security:before,
.pi-times-circle:before {
  content: "\e914";
}
.icon-send:before {
  content: "\ec76";
}
.icon-subscribe:before {
  content: "\e915";
}
.icon-subscription:before {
  content: "\e96f";
}
.icon-target:before {
  content: "\e970";
}
.icon-trophy:before {
  content: "\e919";
}
.icon-visa-card:before {
  content: "\e978";
}
.icon-wallet:before {
  content: "\e979";
}
.icon-linked-in:before {
  content: "\e958";
}
.icon-my-account:before, .icon-personal:before {
  content: "\e92d";
}
.icon-caret-down {
  font-size: 8px !important;
  padding: 5px;

  &:before {
    content: "\e906";
  }
}
.icon-arrow-down:before {
  content: "\e974";
}
.icon-arrow-up:before {
  content: "\e975";
}
.icon-arrow-left:before {
  content: "\e941";
}
.icon-bank:before {
  content: "\e942";
}
.icon-chart-bar:before {
  content: "\e943";
}
.icon-dashboard:before {
  content: "\e93f";
}
.icon-dashboard-filled:before {
  content: "\e948";
}
.icon-calendar:before {
  content: "\e949";
}
.icon-download:before {
  content: "\e94d";
}
.icon-upload:before {
  content: "\e98e";
}
.icon-edit:before {
  content: "\e94e";
}
.icon-help:before {
  content: "\e954";
}
.icon-angle-left:before {
  content: "\e956";
}
.icon-chart-line:before {
  content: "\e959";
}
.icon-pinmap:before {
  content: "\e960";
}
.icon-menu:before {
  content: "\e95c";
}
.icon-more {
  padding: 0 5px;

  &:before {
    content: "\e95d";
  }
}
.icon-chart-pie:before {
  content: "\e95f";
}
.icon-property:before {
  content: "\e962";
}
.icon-report:before {
  content: "\e965";
}
.icon-angle-right:before {
  content: "\e945";
}
.icon-search:before,
.pi-search {
  content: "\e969";
}
.icon-spare:before {
  content: "\e96e";
}
.icon-settings:before {
  content: "\e96d";
}
.icon-tax:before {
  content: "\e971";
}
.icon-trash:before {
  content: "\e973";
}
.icon-users:before {
  content: "\e977";
}
.icon-case:before {
  content: "\e97a";
}
.icon-expenses:before {
  content: "\e900";
}
.icon-income:before {
  content: "\e901";
}
.icon-diamond:before {
  content: "\e902";
}
.icon-bankfeeds:before {
  content: "\e903";
}
.icon-depreciation:before {
  content: "\e904";
}
.icon-share:before {
  content: "\e907";
}
.icon-user-account:before {
  content: "\e908";
}
.icon-car-expenses:before {
  content: "\e909";
}
.icon-property-balance:before {
  content: "\e90a";
}
.icon-home-loan:before {
  content: "\e90b";
}
.icon-bank-protected:before {
  content: "\e90c";
}
.icon-chat:before {
  content: "\e90d";
}
.icon-barcode:before {
  content: "\e91a";
}
.icon-pin-address:before {
  content: "\e91b";
}
.icon-phone:before {
  content: "\e92f";
}
.icon-email:before {
  content: "\2709";
}
.icon-weblink:before {
  content: "\e93c";
}

.icon-copy:before {
  content: "\e90f";
}
.icon-letter:before {
  content: "\e910";
}
.icon-warning:before {
  content: "\e911";
}
.icon-speedometer:before {
  content: "\e912";
}
.icon-car:before {
  content: "\e916";
}
.icon-mail:before {
  content: "\e917";
}
.icon-sole-tank:before, .icon-sole:before {
  content: "\e918";
}
.icon-branches:before {
  content: "\ef22";
}
.icon-checklist:before {
  content: "\ee52";
}
.icon-itp-logo:before {
  content: "\f140";
}

// icon sizes
.is-small {
  font-size: 12px !important;
}

.is-default {
  font-size: 20px !important;
}

.is-big {
  font-size: 30px !important;
}
