@import 'styles/variables';
@import 'styles/breakpoints';

.my-tax-report {
  .mat-stepper-horizontal {
    display: flex !important;
    flex-direction: column;
    height: 100%;

    .mat-horizontal-stepper-header-container {
      .mat-step-header {
        .mat-step-icon-state-number,
        .mat-step-icon-state-edit {
          background-color: transparent;
          color: $color-text;
          display: block;
          font-size: $fs-big;
          font-weight: 700;
          margin-right: 0;

          &.mat-step-icon-selected {
            color: $color-primary;
          }
        }

        .mat-step-label {
          &:not(.mat-step-label-error) {
            display: none;
          }
        }
      }
    }

    .mat-horizontal-content-container {
      flex-grow: 1;
      overflow-y: auto;
      padding: 0;
      position: relative;

      .mat-horizontal-stepper-content {
        // only for current step
        &[aria-expanded="true"] {
          padding: 40px;
          position: absolute;
          width: 100%;

          @include device-small {
            padding: 20px;
          }
        }
      }
    }
  }
}
