@import 'styles/variables';

.tabs {
  display: flex;
  align-items: center;
  margin-top: 30px;
  overflow-x: auto;

  .tab,
  .tab-add {
    min-width: 145px;
    text-align: center;
  }

  .tab {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    padding: 15px;
    position: relative;
    color: $color-secondary;
    font-weight: bold;
    border-radius: 4px 4px 0 0;
    background-color:  $color-white;
    border: 1px solid $color-background-light;
    cursor: pointer;

    i.icon {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 10px;
    }

    &.active {
      background-color: $color-background-light;
    }
  }

  .tab-add {
    font-size: 14px;
    padding: 15px 0;
    color: $color-primary;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background: $color-border;
  }
}
