@import 'styles/variables';
@import 'styles/breakpoints';
@import 'expandable-row';
@import 'table-no-border-top-lg';
@import 'table-bold';
@import 'table-compact';
@import 'table-cell-sm';
@import 'styles/breakpoints';

.mat-mdc-table,
table {
  --app-cell-padding: 10px;
  // to remove the gaps between the raws
  border-collapse: collapse;
  color: $color-text-dark;
  width: 100%;

  .mat-mdc-header-row {
    height: auto;
    background-color: $color-background-light;
    min-height: 30px;
  }

  td.mat-mdc-header-cell,
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell {
    border-top: 1px solid rgba($color-black, .12);
    border-bottom-style: none !important;
    align-items: center;
    box-sizing: border-box;
  }

  .mat-mdc-header-cell,
  .mat-mdc-cell,
  .mat-mdc-footer-cell {
    font-weight: 500;
    padding: 16px 10px;
    border-color: rgba($color-black, .12);
    align-self: stretch;
    font-size: .8rem;
    color: $color-text-dark;
    justify-content: left;
    text-align: left;
    border-bottom: none;

    &:last-of-type {
      text-align: right;
      justify-content: flex-end;
      padding-right: 20px;

      @include device-medium {
        justify-content: space-between;
      }
    }

    &:first-of-type {
      padding-left: 20px;
      justify-content: flex-start;
      text-align: left;

      @include device-medium {
        justify-content: space-between;
      }
    }

    // for menu and other buttons in cell
    &.actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-top: transparent;

      i.icon {
        font-size: $fs-default;
        cursor: pointer;

        &:before {
          color: $color-text;
        }

        &:not(:first-of-type) {
          margin-left: 12px;

          @include device-small {
            margin-left: 12px;
          }
        }
      }
    }
  }

  .mat-mdc-header-cell {
    .mat-sort-header-container {
      .mat-sort-header-button {
        //min-width: auto;
      }
    }

    // align to center th element (but not 1st child)
    &:not(:first-child) {
      .mat-sort-header-container {
        //justify-content: center;
      }
    }
  }

  .mat-mdc-header-row,
  .mat-mdc-footer-row,
  .mat-mdc-row {
    // we need this to replace standard material inherit
    line-height: normal;
    // to be as others cells
    height: auto;
  }

  mat-row.mat-mdc-row, mat-header-row.mat-mdc-header-row, mat-footer-row.mat-mdc-footer-row {
    &:not(:first-child) {
      border-top-width: 1px;
      border-top-style: solid;
      border-top-color: rgba($color-black, .12);
    }
  }

  .mat-mdc-row {
    height: auto;
    min-height: 40px;

    // Colorize row if it has checked checkbox
    &.checked {
      background-color: rgba($color-primary, .1);
    }
  }

  .mat-mdc-footer-cell {
    font-weight: 700;
  }

  // Expansion icon
  i.expand-icon {
    &:not(.expanded) {
      transform: rotate(-90deg);
    }
  }

  &.align-left {
    .mat-mdc-header-cell,
    .mat-mdc-footer-cell,
    .mat-mdc-cell {
      &:not(:last-of-type) {
        text-align: left;
      }
    }
  }

  caption {
    margin-bottom: 10px;
    text-align: left;

    &.primary {
      background-color: $color-primary;
      color: $color-white;
      font-size: $fs-default;
      font-weight: 700;
      padding: 10px 10px 10px 20px;
    }
  }

  mat-cell {
    &.collapsed {
      display: none;
    }
  }

  mat-row, mat-header-row, mat-footer-row {
    display: flex;
    border-width: 0;
    border-top: 1px solid rgba($color-black, .12);
    align-items: center;
    box-sizing: border-box;
  }

  mat-row, mat-header-row, mat-footer-row, th.mat-header-cell, td.mat-mdc-cell, td.mat-mdc-footer-cell {
    border-top-color: rgba($color-black, .12);
  }
}


.m-caption {
  display: none;
}

@include device-small {
  .mat-mdc-table {
    .mat-mdc-header-row {
      min-height: 0;
      border-top: 0;
    }

    .mat-mdc-footer-row,
    .mat-mdc-row {
      flex-direction: column;
      min-height: 0;
    }

    .mat-mdc-header-cell {
      display: none;
    }

    .mat-mdc-cell,
    .mat-mdc-footer-cell {
      position: relative !important;
      padding: var(--app-cell-padding) !important;
      display: flex;
      justify-content: space-between;

      .m-caption {
        display: block;
        color: $color-text;
      }

      .value {
        text-align: right;
        color: $color-secondary;
      }

      &.mat-column-actions {
        i {
          font-size: 20px !important;
        }
      }

      &:empty {
        display: none;
      }
    }
  }

  .mdc-data-table__row:first-child .mat-mdc-cell {
    //we need this border in tables with the regular table markup
    &:not(td) {
      border-top: 0 !important;
    }
  }
}
