@import 'styles/variables';
@import 'styles/breakpoints';

/**
 * Custom styles for My-tax-report
 */
.my-tax-report {
  mat-form-field.mat-mdc-form-field {
    &:not(.mat-mdc-paginator-page-size-select) {
      .mat-mdc-text-field-wrapper {
        --app-mat-field-flex-margin-top: 0;
        margin-bottom: 0;
        margin-top: 0;
        padding-bottom: 2px;
        font-weight: 400;

        .mat-mdc-form-field-flex {
          align-items: center;
          background-color: $color-white;
          border: 1px solid $color-text;
          border-radius: 3px;
          height: 36px;
          padding: 0 10px;

          .mat-mdc-form-field-icon-prefix {
            border-right: 1px solid $color-text;
            font-size: $fs-big;
            line-height: 36px;
            padding-right: 10px;
          }

          .mat-mdc-form-field-icon-suffix {
            border-left: 1px solid $color-text;
            line-height: 36px;
            margin-right: 0;
            padding-left: 10px;
          }

          .mat-mdc-form-field-infix {
            @include device-small {
              width: 100%;
            }
          }
        }
      }

      &.mat-form-field-invalid {
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            border: 2px solid $color-error;

            .mat-mdc-form-field-icon-prefix {
              border-right: 2px solid $color-error;
            }

            .mat-mdc-form-field-icon-suffix {
              border-left: 2px solid $color-error;
            }
          }
        }
      }
    }
  }

  // mat-form-field select
  .mat-form-field-hide-placeholder {
    .mat-mdc-select-placeholder {
      color: $color-text;
      -webkit-text-fill-color: $color-text;
    }
  }
}
