@import 'styles/breakpoints';
@import 'styles/variables';

// to make mini version of table
.mat-mdc-table,
table {
  &.table-compact {
    .mat-mdc-header-cell {
      color: $color-secondary;
      font-weight: 700;
    }

    .mat-mdc-cell {
      color: $color-secondary;
      font-size: $fs-extra-small;
      padding: 10px 20px;
    }
  }
}
