@import 'styles/variables';


//colors
$checkbox-disabled-bg-color: $background-success-section;
$checkbox-bg-color: $color-white;
$checkbox-outline-color: $color-border-secondary;

//dimensions
$checkbox-border-radius: 2px;
$checkbox-unchecked-border-width: 1px;
$checkbox-checked-border-width: 2px;

//borders
$checkbox-unchecked-border: $checkbox-unchecked-border-width solid $checkbox-outline-color;
$checkbox-checked-border: $checkbox-checked-border-width solid $color-primary;
